import React from "react";
import DropDownButton from "devextreme-react/drop-down-button";

const ActionsInvoiceQuoteForm = (props) => {
  const {
    quoteId,
    onValidate,
    onHandleSubmit,
    OnPrintInNewWindow,
    actions,
    onActionClick,
    onHandleCanceled,
  } = props;
  return (
    <React.Fragment>
      <button
        disabled={onValidate()}
        className="btn btn-primary m-2"
        onClick={(e) => onHandleSubmit(e)}
      >
        Enregistrer
      </button>
      {quoteId === "new" ? null : (
        <button
          className="btn btn-info m-2"
          onClick={() => OnPrintInNewWindow(quoteId)}
        >
          Exporter
        </button>
      )}
      {quoteId === "new" ? null : (
        <DropDownButton
          text="Statut"
          icon=""
          items={actions}
          keyExpr="id"
          displayExpr="text"
          dropDownOptions={{ width: 150, style: { class: "btn" } }}
          onItemClick={(e) => onActionClick(e)}
          width={100}
          height={38}
        />
      )}
      {quoteId === "new" ? null : (
        <button
          className="btn btn-light m-2"
          onClick={() => onHandleCanceled()}
        >
          Annuler
        </button>
      )}
    </React.Fragment>
  );
};

export default ActionsInvoiceQuoteForm;
