import React from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";

const Modal = ({
  isPopupVisible,
  message,
  width,
  height,
  togglePopup,
  toolbarItems = [],
}) => {
  const closeButtonOptions = {
    text: "Annuler",
    stylingMode: "contained",
    type: "normal",
    onClick: togglePopup,
  };
  return (
    <Popup
      id="popup"
      visible={isPopupVisible}
      closeOnOutsideClick={true}
      onHiding={togglePopup}
      width={width}
      height={height}
      position="top"
      showTitle={false}
    >
      <p>{message}</p>
      {toolbarItems.map((t, index) => (
        <ToolbarItem
          key={index}
          widget="dxButton"
          toolbar="bottom"
          location="center"
          options={t.options}
        />
      ))}
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={closeButtonOptions}
      />
    </Popup>
  );
};

export default Modal;
