import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import enterpriseService from "../services/enterpriseService";
import errors from "./utils/errors";
import { toast } from "react-toastify";
import userService from "../services/userService";

class EnterpriseForm extends Form {
  state = {
    data: {
      name: "",
      webSite: "",
      mail: "",
      phone: "",
      adress: "",
      nif: "",
      rateTVA: "",
      interimId: "",
    },
    users: [],
    errors: {},
  };

  schema = {
    id: Joi.any().optional(),
    name: Joi.string().required().label("Libelle").error(errors),
    webSite: Joi.any().optional(),
    mail: Joi.any().optional(),
    phone: Joi.any().optional(),
    adress: Joi.any().optional(),
    nif: Joi.any().optional(),
    rateTVA: Joi.any().optional(),
    interimId: Joi.any().optional(),
  };

  async populateEnterprise() {
    const enterpriseId = this.props.match.params.id;
    try {
      if (enterpriseId === "new") {
        const enterprise = {
          name: "",
          webSite: "",
          mail: "",
          phone: "",
          adress: "",
          nif: "",
          rateTVA: "",
          interimId: "",
        };
        this.setState({ data: enterprise });
        return;
      }
      const { data } = await enterpriseService.getEnterprise(
        parseInt(enterpriseId)
      );
      data.interimId = data.interimId === null ? "" : data.interimId.toString();
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    const { data: users } = await userService.getUsersWithName();
    this.setState({ users });
    await this.populateEnterprise();
  }

  doSubmit = async () => {
    const data = this.state.data;
    try {
      await enterpriseService.saveEnterprise(data);
      this.props.history.push("/list-enterprises");
      toast.success("L'entreprise a été enregistrée.");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        if (ex.response.data.errors.Name) {
          errors.enterpriseId = "Ce champ est requis";
        }
        this.setState({ errors });
      }
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="w-50">
          {this.renderInput("name", "Libelle")}
          {this.renderInput("webSite", "Site web")}
          {this.renderInput("mail", "Mail")}
          {this.renderInput("phone", "Téléphone")}
          {this.renderTextarea("adress", "Adresse")}
          {this.renderInput("nif", "Nif")}
          {this.renderInput("rateTVA", "Taux TVA", "number")}
          {this.renderDataListString(
            "interimId",
            "Signataire",
            this.state.users,
            "allinterimaires"
          )}
          {this.renderButton("Enregistrer")}
        </form>
      </div>
    );
  }
}

export default EnterpriseForm;
