import React, { useEffect, useState } from "react";
import detailSectionService from "../services/detailSectionService";
import Grid from "./common/Grid";

function ReadOnlyDetailSections(props) {
  const [families, setDetailSections] = useState([]);
  const columns = [
    { dataField: "number", caption: "N°" },
    { dataField: "name", caption: "Libelle" },
  ];
  const editing = {
    mode: "row",
    allowAdding: false,
    allowDeleting: false,
    allowUpdating: false,
  };

  useEffect(() => {
    (async function () {
      const { data } = await detailSectionService.getDetailSections();
      setDetailSections(data);
    })();
  }, [props]);

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={families}
        editing={editing}
        isGoupPanelVisible={true}
      />
    </div>
  );
}

export default ReadOnlyDetailSections;
