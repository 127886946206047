import http from "./httpService";

const endPoint = "applicationUsers";

function getUsers() {
  return http.get(userUrl("GetUsers"));
}
function getUsersWithName() {
  return http.get(userUrl("GetUsersWithName"));
}

function userUrl(id) {
  return `${endPoint}/${id}`;
}

function saveUserRoles(userRoles) {
  return http.post(endPoint, userRoles);
}

function getUser(userId) {
  return http.get(userUrl(`GetApplicationUser/${userId}`));
}

export default {
  getUsers,
  getUser,
  getUsersWithName,
  saveUserRoles,
};
