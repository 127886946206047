import React, { Component } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { LoginMenu } from "./api-authorization/LoginMenu";
import "./NavMenu.css";
import UserContext from "./context/userContext";
import hasRole from "./utils/userPermissions";
import roles from "./utils/roles";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <UserContext.Consumer>
        {(currentUser) => (
          <header>
            <Navbar
              expand="md"
              className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow mb-3 bg-primary"
              light
            >
              <Container>
                <NavbarBrand tag={Link} to="/" className="text-white ">
                  {/* Devis & Facturation */}
                  Invoice
                </NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <Collapse
                  className="d-sm-inline-flex flex-sm-row-reverse "
                  isOpen={!this.state.collapsed}
                  navbar
                >
                  <ul className="navbar-nav flex-grow">
                    <NavItem>
                      <NavLink tag={Link} className="text-white" to="/dashbord">
                        Tableau de bord
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-white"
                        to="/list-articles"
                      >
                        Articles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-white"
                        to="/list-detailSections"
                      >
                        Catégories
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-white"
                        to="/list-customers"
                      >
                        Clients
                      </NavLink>
                    </NavItem>
                    {hasRole(currentUser, roles.canManageExpensesNatures) ? (
                      <NavItem>
                        <NavLink
                          tag={Link}
                          exact="true"
                          className="text-white"
                          to="/list-expenses"
                        >
                          Dépenses
                        </NavLink>
                      </NavItem>
                    ) : null}
                    <NavItem>
                      <NavLink
                        tag={Link}
                        exact="true"
                        className="text-white"
                        to="/list-quotes"
                      >
                        Devis
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-white"
                        to="/list-invoices"
                      >
                        Factures
                      </NavLink>
                    </NavItem>
                    {hasRole(currentUser, roles.canCreateEntity) ? (
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="text-white">
                          Créez
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <NavLink tag={Link} to="/article/new">
                              Article
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/detailSection/new">
                              Catégorie
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/customer/new">
                              Client
                            </NavLink>
                          </DropdownItem>

                          <DropdownItem>
                            <NavLink tag={Link} to="/quote/new">
                              Devis
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/invoice/new">
                              Facture
                            </NavLink>
                          </DropdownItem>

                          <DropdownItem>
                            <NavLink tag={Link} to="/unit/new">
                              Unité
                            </NavLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : null}
                    {hasRole(currentUser, roles.canManageUsers) ? (
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="text-white">
                          Paramètres
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <NavLink tag={Link} to="/list-activities">
                              Activités
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/list-enterprises">
                              Entreprise
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/natures-expenses">
                              Nature depenses
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/list-acceptedPayments">
                              Mode paiment
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/user-roles">
                              Roles utilisateur
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink tag={Link} to="/list-units">
                              Unités
                            </NavLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : null}
                    <LoginMenu></LoginMenu>
                  </ul>
                </Collapse>
              </Container>
            </Navbar>
          </header>
        )}
      </UserContext.Consumer>
    );
  }
}
