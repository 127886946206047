import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import errors from "./utils/errors";
import quoteService from "../services/quoteService";
import expensesNatureService from "../services/expensesNatureService";
import expenseService from "../services/expenseService";
import { toast } from "react-toastify";

class ExpenseForm extends Form {
  state = {
    data: { libelle: "", quoteId: "", expensesNatureId: "", amount: "" },
    errors: {},
    quotes: [],
    expensesNatures: [],
  };

  schema = {
    id: Joi.any().optional(),
    libelle: Joi.string().required().label("Libelle").error(errors),
    quoteId: Joi.string().required().label("Devis").error(errors),
    expensesNatureId: Joi.string()
      .required()
      .label("Nature dépense")
      .error(errors),
    amount: Joi.number().min(0).required().label("Montant").error(errors),
    lastUpdate: Joi.any().optional(),
  };

  async populateExpense() {
    const expenseId = this.props.match.params.id;
    try {
      if (expenseId === "new") {
        const expense = {
          libelle: "",
          quoteId: "",
          expensesNatureId: "",
          amount: "",
        };
        this.setState({ data: expense });
        return;
      }
      const { data } = await expenseService.getExpense(parseInt(expenseId));
      data.amount = data.amount.toString();
      data.quoteId = data.quoteId.toString();
      data.expensesNatureId = data.expensesNatureId.toString();
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    const { data: quotes } = await quoteService.getGetQuotesOnlyWithNumber();
    const { data: expensesNatures } =
      await expensesNatureService.getExpensesNatures();
    this.setState({ quotes, expensesNatures });
    await this.populateExpense();
  }

  doSubmit = async () => {
    try {
      await expenseService.saveExpense(this.convertExpenseProperties());
      this.props.history.push("/list-expenses");
      toast.success("La dépense a été enregistrée");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        if (ex.response.data.errors && ex.response.data.errors.UnitId) {
          errors.quoteId =
            "Veuillez chosir un numéro de devis valide dans la liste";
        }
        if (
          ex.response.data.errors &&
          ex.response.data.errors.expensesNatureId
        ) {
          errors.expensesNatureId =
            "Veuillez chosir une nature de dépense valide dans la liste";
        }
        this.setState({ errors });
      }
    }
  };

  convertExpenseProperties() {
    const expense = { ...this.state.data };
    expense.quoteId = parseInt(expense.quoteId) || 0;
    expense.expensesNatureId = parseInt(expense.expensesNatureId) || 0;
    return expense;
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="w-50">
          {this.renderDataList("quoteId", "Devis", this.state.quotes, "quotes")}
          {this.renderDataList(
            "expensesNatureId",
            "Catégorie",
            this.state.expensesNatures,
            "expensesNatures"
          )}
          {this.renderInput("libelle", "Objet")}
          {this.renderInput("amount", "Prix", "number")}
          {this.renderButton("Enregistrer")}
        </form>
      </div>
    );
  }
}

export default ExpenseForm;
