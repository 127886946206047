import http from "./httpService";

const endPoint = "roles";

function getRoles() {
  return http.get(roleUrl("GetRoles"));
}

function roleUrl(id) {
  return `${endPoint}/${id}`;
}

function saveRole(role) {
  if (role.id === undefined || role.id === 0) return http.post(endPoint, role);
  return http.put(roleUrl(role.id), role);
}

function getRole(roleId) {
  return http.get(roleUrl(`GetApplicationRole/${roleId}`));
}

export default {
  getRoles,
  getRole,
  saveRole,
};
