import React, { useContext, useEffect, useState } from "react";

import quoteService from "../services/quoteService";
import Grid from "./common/Grid";
import { enumState, state } from "./utils/state";
import { stateRender } from "./utils/stateCell";
import {
  actions,
  actionsWithFacture,
  actionsWithFactureDisabled,
  readOnlyActionsWithFacture,
  readOnlyActions,
  readOnlyActionsWithFactureDisabled,
} from "./utils/actions";
import printInNewWindow from "./utils/printInNewWindow";
import { toast } from "react-toastify";
import Modal from "./common/Modal";
import choiseActions from "./utils/choiseActions";
import invoiceService from "../services/invoiceService";
import ModalDownPayment from "./common/ModalDownPayment";
import hasRole from "./utils/userPermissions";
import UserContext from "./context/userContext";

export default function ReadOnlyQuotes(props) {
  const currentUer = useContext(UserContext);
  const [quotes, setQuotes] = useState([]);
  const [modalOptions, SetModalOptions] = useState({
    isPopupVisible: false,
    width: 500,
    height: 222,
    message: "Confirmer la suppression?",
    toolbarItems: [],
  });

  const [modalDownPayment, SetModalDownPayment] = useState({
    isPopupVisible: false,
    quote: { invoices: [] },
  });

  const columns = [
    { dataField: "number", caption: "Numéro" },
    {
      dataField: "state",
      caption: "Statut",
      dataSource: state,
      displayExpr: "value",
      valueExpr: "key",
      cellRender: stateRender,
    },
    { dataField: "customer.name", caption: "Client" },
    { dataField: "projectName", caption: "Projet" },
    { dataField: "quoteDate", caption: "En date du", dataType: "date" },
    { dataField: "totalHT", caption: "Total", format: "#,##0 CFA" },
  ];
  const editing = {
    mode: "row",
    allowAdding: false,
  };

  useEffect(() => {
    async function getQuotes() {
      const { data: allQuotes } = await quoteService.getQuotes();
      setQuotes(allQuotes);
    }

    getQuotes();
  }, [props]);

  const handleChangeQuoteState = async (quoteId, state) => {
    const { data: quote } = await quoteService.changeQuoteState(quoteId, state);
    const allQuotes = [...quotes];
    const quoteToChange = allQuotes.find((q) => q.id === quote.id);
    const index = allQuotes.indexOf(quoteToChange);
    allQuotes[index] = quote;
    setQuotes(allQuotes);
    toast.success(`Le statut du devis N°${quote.number} a changé`);
  };

  const handleDeleteQuote = async (quoteId) => {
    let allQuotes = [...quotes];
    allQuotes = allQuotes.filter((a) => a.id !== quoteId);
    setQuotes(allQuotes);
    await quoteService.deleteQuote(quoteId);
    toast.success("Le devis a été supprimé");
  };

  const renderModal = (message, toolbarItems) => {
    SetModalOptions((prevState) => ({
      ...prevState,
      isPopupVisible: true,
      message,
      toolbarItems,
    }));
  };

  const renderModalDownPayment = (quote) => {
    SetModalDownPayment((prevState) => ({
      ...prevState,
      isPopupVisible: true,
      quote,
    }));
  };

  const togglePopup = () => {
    SetModalOptions((prevState) => ({
      ...prevState,
      isPopupVisible: !modalOptions.isPopupVisible,
    }));
  };

  const togglePopupDownPayment = () => {
    SetModalDownPayment((prevState) => ({
      ...prevState,
      isPopupVisible: !modalDownPayment.isPopupVisible,
    }));
  };

  const createInvoiceByQuoteId = async (quoteId) => {
    try {
      const { data: invoice } = await invoiceService.createInvoiceByQuoteId(
        quoteId
      );
      props.history.push(`/invoice/${invoice.id}`);
      toast.success("La facture finale a été crée");
    } catch (error) {}
  };

  const onActionClick = async ({ itemData: action }, { data: row }) => {
    const quotesActions = choiseActions(row, [
      readOnlyActionsWithFacture,
      readOnlyActions,
      readOnlyActionsWithFactureDisabled,
    ]);
    switch (action.id) {
      case quotesActions[0].id:
        printInNewWindow(row.id);
        break;
      case quotesActions[1].id:
        const { data: quote } = await quoteService.getQuoteWithInvoices(row.id);
        renderModalDownPayment(quote);
        break;
      case quotesActions[2].id:
        createInvoiceByQuoteId(row.id);
        break;
      default:
        break;
    }
  };

  const redirect = (id) => {
    props.history.push(`/invoice/${id}`);
  };

  return (
    <div>
      <h4>Liste des devis</h4>
      <Modal
        isPopupVisible={modalOptions.isPopupVisible}
        togglePopup={togglePopup}
        width={modalOptions.width}
        height={modalOptions.height}
        message={modalOptions.message}
        toolbarItems={modalOptions.toolbarItems}
      />
      <ModalDownPayment
        isPopupVisible={modalDownPayment.isPopupVisible}
        togglePopup={togglePopupDownPayment}
        quote={modalDownPayment.quote}
        redirect={redirect}
      />
      <Grid
        columns={columns}
        dataSource={quotes}
        editing={editing}
        isGoupPanelVisible={true}
        dropDownButton={true}
        actions={
          hasRole(currentUer, "CanCreateInvoiceByQuote")
            ? [
                readOnlyActionsWithFacture,
                readOnlyActions,
                readOnlyActionsWithFactureDisabled,
              ]
            : [readOnlyActions, readOnlyActions, readOnlyActions]
        }
        onActionClick={onActionClick}
      />
    </div>
  );
}
