import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import articleService from "../services/articleService";
import Grid from "./common/Grid";

export default function Articles(props) {
  const [articles, setArticles] = useState([]);
  const columns = [
    { dataField: "number", caption: "N°" },
    { dataField: "detailSection.name", caption: "Catégorie" },
    { dataField: "name", caption: "Article" },
    {
      dataField: "price",
      caption: "Prix",
      dataType: "number",
      format: "#,##0 CFA",
    },
    { dataField: "unit.name", caption: "Unité" },
  ];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: true,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier l'article",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/article/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    async function getArticles() {
      const { data: articles } = await articleService.getArticles();
      setArticles(articles);
    }

    getArticles();
  }, [props]);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint = "Ajouter un nouvel article";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/article/new");
    e.toolbarOptions.items.unshift();
  };

  const handleOnRowRemoved = async ({ key }) => {
    try {
      await articleService.deleteArticle(key);
      toast.success("L'article a été supprimée");
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error(ex.response.data);
    }
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={articles}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
        onRowRemoved={handleOnRowRemoved}
      />
    </div>
  );
}
