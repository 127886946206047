import http from "./httpService";

const endPoint = "acceptedPayments";

function acceptedpaymentUrl(id) {
  return `${endPoint}/${id}`;
}

function saveAcceptedPayment(acceptedpayment) {
  if (acceptedpayment.id === undefined || acceptedpayment.id === 0)
    return http.post(endPoint, acceptedpayment);
  return http.put(acceptedpaymentUrl(acceptedpayment.id), acceptedpayment);
}

function getAcceptedPayments() {
  return http.get(acceptedpaymentUrl("GetAcceptedPayments"));
}

function getAcceptedPayment(acceptedpaymentId) {
  return http.get(
    acceptedpaymentUrl(`GetAcceptedPayment/${acceptedpaymentId}`)
  );
}

function getGetAcceptedPaymentsFormating() {
  return http.get(acceptedpaymentUrl(`GetAcceptedPaymentsFormating`));
}

export default {
  getAcceptedPayment,
  getAcceptedPayments,
  saveAcceptedPayment,
  getGetAcceptedPaymentsFormating,
};
