import http from "./httpService";

const endPoint = "expenses";

function expenseUrl(id) {
  return `${endPoint}/${id}`;
}

function saveExpense(expense) {
  if (expense.id === undefined || expense.id === 0)
    return http.post(endPoint, expense);
  return http.put(expenseUrl(expense.id), expense);
}

function getExpenses() {
  return http.get(endPoint);
}

function getExpense(expenseId) {
  return http.get(expenseUrl(`GetExpense/${expenseId}`));
}

function deleteExpense(expenseId) {
  return http.delete(expenseUrl(expenseId));
}

export default {
  saveExpense,
  getExpenses,
  getExpense,
  deleteExpense,
};
