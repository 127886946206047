import http from "./httpService";

const endPoint = "quotes";

function quoteUrl(id) {
  return `${endPoint}/${id}`;
}

function saveQuote(quote) {
  if (quote.id === undefined || quote.id === 0)
    return http.post(endPoint, quote);
  return http.put(quoteUrl(quote.id), quote);
}

function getQuotes() {
  return http.get(quoteUrl("GetQuotes"));
}
function getGetQuotesOnlyWithNumber() {
  return http.get(quoteUrl("GetQuotesOnlyWithNumber"));
}
function getAcceptedQuotesWithoutInvoices() {
  return http.get(quoteUrl("GetAcceptedQuotesWithoutInvoices"));
}

function getQuote(quoteId) {
  return http.get(quoteUrl(`GetQuote/${quoteId}`));
}

function getQuoteWithInvoices(quoteId) {
  return http.get(quoteUrl(`GetQuoteWithInvoices/${quoteId}`));
}

function getNewQuoteNumber() {
  return http.get(quoteUrl("GetNewQuoteNumber"));
}

function changeQuoteState(quoteId, state) {
  return http.patch(quoteUrl(`ChangeQuoteState/${quoteId}/${state}`));
}

function deleteQuote(quoteId) {
  return http.delete(quoteUrl(quoteId));
}

export default {
  saveQuote,
  getQuotes,
  getQuote,
  getNewQuoteNumber,
  getQuoteWithInvoices,
  getAcceptedQuotesWithoutInvoices,
  getGetQuotesOnlyWithNumber,
  changeQuoteState,
  deleteQuote,
};
