import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import expenseService from "../services/expenseService";
import Grid from "./common/Grid";

export default function Expenses(props) {
  const [expenses, setExpenses] = useState([]);
  const columns = [
    { dataField: "quote.name", caption: "N° devis" },
    { dataField: "expensesNature.name", caption: "Nature dépense" },
    { dataField: "libelle", caption: "Objet" },
    {
      dataField: "amount",
      caption: "Montant",
      dataType: "number",
      format: "#,##0 CFA",
    },
  ];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: true,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier la dépense",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/expense/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    async function getExpenses() {
      const { data: expenses } = await expenseService.getExpenses();
      setExpenses(expenses);
    }

    getExpenses();
  }, [props]);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint = "Ajouter une nouvelle dépense";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/expense/new");
    e.toolbarOptions.items.unshift();
  };

  const handleOnRowRemoved = async ({ key }) => {
    try {
      await expenseService.deleteExpense(key);
      toast.success("la dépense a été supprimée");
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error(ex.response.data);
    }
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={expenses}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
        onRowRemoved={handleOnRowRemoved}
      />
    </div>
  );
}
