import React, { useEffect, useState } from "react";
import expensesNatureService from "../services/expensesNatureService";
import Grid from "./common/Grid";

function ExpensesNatures(props) {
  const [expensesNatures, setExpensesNatures] = useState([]);
  const columns = [{ dataField: "name", caption: "Libelle" }];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: false,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier la nature de dépense",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/expensesNature/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    (async function () {
      const { data } = await expensesNatureService.getExpensesNatures();
      setExpensesNatures(data);
    })();
  }, [props]);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint =
      "Ajouter une nouvelle nature de dépenses";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/expensesNature/new");
    e.toolbarOptions.items.unshift();
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={expensesNatures}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
      />
    </div>
  );
}

export default ExpensesNatures;
