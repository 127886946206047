import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import errors from "./utils/errors";
import customerService from "../services/customerService";
import { toast } from "react-toastify";
class CustomerForm extends Form {
  state = {
    data: {
      type: "",
      firstName: "",
      lastName: "",
      enterpriseName: "",
      adresses: "",
      phone: "",
      otherPhone: "",
      email: "",
      otherEmail: "",
    },
    firstNames: [],
    lastNames: [],
    enterpriseNames: [],
    adresses: [],
    phones: [],
    otherPhones: [],
    emails: [],
    otherEmails: [],
    errors: {},
  };

  schema = {
    id: Joi.any().optional(),
    type: Joi.string().required().label("Type").error(errors),
    firstName: Joi.any().optional(),
    lastName: Joi.any().optional(),
    enterpriseName: Joi.any().optional(),
    adresses: Joi.any().optional(),
    phone: Joi.any().optional(),
    otherPhone: Joi.any().optional(),
    email: Joi.string().allow("").email().label("Mail").error(errors),
    otherEmail: Joi.any().optional(),
  };

  async populateCustomer() {
    const customerId = this.props.match.params.id;
    try {
      const { data: firstNames } =
        await customerService.getCustomersFirstName();
      const { data: lastNames } = await customerService.getCustomersLastName();
      const { data: enterpriseNames } =
        await customerService.getCustomersEnterpriseName();
      const { data: adresses } = await customerService.getCustomersAdresses();
      const { data: phones } = await customerService.getCustomersPhone();
      const { data: otherPhones } =
        await customerService.getCustomersOtherPhone();
      const { data: emails } = await customerService.getCustomersEmail();
      const { data: otherEmails } =
        await customerService.getCustomersOtherEmail();
      if (customerId === "new") {
        const customer = {
          type: "",
          firstName: "",
          lastName: "",
          enterpriseName: "",
          adresses: "",
          phone: "",
          otherPhone: "",
          email: "",
          otherEmail: "",
        };
        // this.setState({ data: customer });
        this.setState({
          data: customer,
          firstNames,
          lastNames,
          enterpriseNames,
          adresses,
          phones,
          otherPhones,
          emails,
          otherEmails,
        });
        return;
      }
      const { data } = await customerService.getCustomer(parseInt(customerId));

      data.type = data.type.toString();
      this.setState({
        data,
        firstNames,
        lastNames,
        enterpriseNames,
        adresses,
        phones,
        otherPhones,
        emails,
        otherEmails,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateCustomer();
  }

  convertCustomerProperties() {
    const customer = { ...this.state.data };
    customer.type = parseInt(customer.type);
    return customer;
  }

  doSubmit = async () => {
    try {
      await customerService.saveCustomer(this.convertCustomerProperties());
      this.props.history.push("/list-customers");
      toast.success("Le client a été enregistré");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.type = ex.response.data;
        this.setState({ errors });
      }
    }
  };
  render() {
    const {
      firstNames,
      lastNames,
      enterpriseNames,
      adresses,
      phones,
      otherPhones,
      emails,
      otherEmails,
    } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="w-50">
          {this.renderSelect("type", "Type", [
            { id: 1, name: "Particulier" },
            { id: 2, name: "Entreprise" },
          ])}
          {/* {this.renderInput("firstName", "Prénom")} */}
          {this.renderSimpleDataList(
            "firstName",
            "Prénom",
            firstNames,
            "firstNames"
          )}
          {/* {this.renderInput("lastName", "Nom")} */}
          {this.renderSimpleDataList("lastName", "Nom", lastNames, "lastNames")}
          {/* {this.renderInput("enterpriseName", "Nom de l'entreprise")} */}
          {this.renderSimpleDataList(
            "enterpriseName",
            "Nom de l'entreprise",
            enterpriseNames,
            "enterpriseNames"
          )}
          {/* {this.renderInput("adresses", "Adresse")} */}
          {this.renderSimpleDataList(
            "adresses",
            "Adresse",
            adresses,
            "name-adresses"
          )}
          {/* {this.renderInput("phone", "Contact 1")} */}
          {this.renderSimpleDataList("phone", "Contact 1", phones, "phones")}
          {/* {this.renderInput("otherPhone", "Contact 2")} */}
          {this.renderSimpleDataList(
            "otherPhone",
            "Contact 2",
            otherPhones,
            "otherPhones"
          )}
          {/* {this.renderInput("email", "E-mail 1")} */}
          {this.renderSimpleDataList("email", "E-mail 1", emails, "emails")}
          {/* {this.renderInput("otherEmail", "E-mail 2")} */}
          {this.renderSimpleDataList(
            "otherEmail",
            "E-mail 2",
            otherEmails,
            "otherEmails"
          )}
          {this.renderButton("Enregistrer")}
        </form>
      </div>
    );
  }
}

export default CustomerForm;
