import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import errors from "./utils/errors";
import roleService from "../services/roleService";
import CheckBox from "./common/CheckBox";
import { toast } from "react-toastify";
import userService from "../services/userService";
import DataListString from "./common/DataListString";

class UserRoles extends Form {
  state = {
    data: { userId: "", roles: [] },
    errors: {},
    users: [],
    roles: [],
  };

  schema = {
    // id: Joi.any().optional(),
    userId: Joi.string().required().label("Utilisateur").error(errors),
    roles: Joi.any().optional(),
  };

  async componentDidMount() {
    const { data: users } = await userService.getUsers();
    const { data: roles } = await roleService.getRoles();
    this.setState({ users, roles });
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };

    if (input.name === "roles") {
      if (!input.checked) {
        data.roles = data.roles.filter((f) => f !== input.value);
        return this.setState({ data });
      }
      data.roles.push(input.value);
      return this.setState({ data });
    }
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleChangeDataList = ({ currentTarget: input }, options) => {
    const item = options.find((d) => d.name.trim() === input.value.trim());
    const value = item !== undefined ? item.id : input.value;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input.name, value.toString());
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = value.toString();
    if (item !== undefined)
      for (const role of item.roles) {
        data.roles.push(role);
      }
    else data.roles = [];
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    try {
      await userService.saveUserRoles(this.state.data);
      this.props.history.push("/user-roles");
      toast.success("l'enregistrement a été effectué");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
      }
    }
  };

  render() {
    const { roles, users, data } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="card">
            <div className="card-header">
              <DataListString
                id={"userId"}
                list={"users"}
                name={"userId"}
                data={users}
                label={"Utilisateur"}
                value={data["userId"]}
                onChange={(e) => this.handleChangeDataList(e, users)}
                error={errors["userId"]}
              />
            </div>
            <div className="card-body">
              <h2>Roles</h2>
              <div className="mb-3 card-text">
                {roles.map((role) => (
                  <CheckBox
                    key={role.id}
                    value={role.name}
                    label={role.description}
                    id={role.id}
                    name="roles"
                    checked={data.roles.includes(role.name)}
                    onChange={this.handleChange}
                  ></CheckBox>
                ))}
              </div>
            </div>
            <div className="card-footer text-muted">
              {this.renderButton("Enregistrer")}
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default UserRoles;
