import http from "./httpService";

const endPoint = "units";

function unitUrl(id) {
  return `${endPoint}/${id}`;
}

function saveUnit(unit) {
  if (unit.id === undefined || unit.id === 0) return http.post(endPoint, unit);
  return http.put(unitUrl(unit.id), unit);
}

function getUnits() {
  return http.get(endPoint);
}

function getUnitsWithCode() {
  return http.get(unitUrl("GetUnitsWithCode"));
}

function getUnit(unitId) {
  return http.get(unitUrl(`GetUnit/${unitId}`));
}

export default {
  getUnit,
  getUnits,
  getUnitsWithCode,
  saveUnit,
};
