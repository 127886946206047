import React, { useEffect, useState } from "react";
import acceptedPaymentService from "../services/acceptedPaymentService";

function AcceptedPaymentFormating(props) {
  const [acceptedPayments, setAcceptedPayments] = useState([]);
  useEffect(() => {
    (async function () {
      const { data } = await acceptedPaymentService.getAcceptedPayments();
      setAcceptedPayments(data);
    })();
  }, [props]);

  return (
    <div>
      Méthodes de paiment acceptées:
      {acceptedPayments.map((a, i) => (
        <ul key={i}>
          <li>
            {a.libelle} {a.value ? ":" : null} {a.value}
          </li>
        </ul>
      ))}
    </div>
  );
}

export default AcceptedPaymentFormating;
