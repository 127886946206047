export default function populateUnitId(details, artilces) {
  details.forEach((item) => {
    if (item.articleId && artilces.length > 0) {
      const article = artilces.find(
        (d) => parseInt(d.id) === parseInt(item.articleId)
      );
      item.unitId = article.unit.id;
      item.articleId = item.articleId.toString();
    } else if (item.detailSectionId) {
      item.detailSectionId = item.detailSectionId.toString();
    }
    item.number = parseFloat(item.number);
    item.numberSection = parseFloat(item.numberSection);
  });
}
