import React, { useEffect, useState } from "react";
import invoiceService from "../services/invoiceService";
import Grid from "./common/Grid";
import { liststateInvoice } from "./utils/state";
import { stateInvoiceRender } from "./utils/stateCell";
import { actionsInvoice, readOnlyActionsInvoice } from "./utils/actions";
import { printInvoiceInNewWindow } from "./utils/printInNewWindow";

export default function ReadOnlyInvoices(props) {
  const [invoices, setInvoices] = useState([]);

  const columns = [
    { dataField: "number", caption: "Numéro" },
    {
      dataField: "type",
      caption: "Type",
      dataSource: [
        { key: 1, value: "Finale" },
        { key: 2, value: "Acompte" },
      ],
      displayExpr: "value",
      valueExpr: "key",
    },
    {
      dataField: "state",
      caption: "Statut",
      dataSource: liststateInvoice,
      displayExpr: "value",
      valueExpr: "key",
      cellRender: stateInvoiceRender,
    },
    { dataField: "customer.name", caption: "Client" },
    { dataField: "quote.name", caption: "N° devis" },
    { dataField: "projectName", caption: "Projet" },
    { dataField: "invoiceDate", caption: "En date du", dataType: "date" },
    { dataField: "totalHT", caption: "Total", format: "#,##0 CFA" },
    { dataField: "reste", caption: "Restant dû", format: "#,##0 CFA" },
  ];
  const editing = {
    mode: "row",
    allowAdding: false,
  };

  useEffect(() => {
    async function getInvoices() {
      const { data } = await invoiceService.getInvoices();
      setInvoices(data);
    }

    getInvoices();
  }, [props]);

  const onActionClick = async ({ itemData: action }, { data: row }) => {
    switch (action.id) {
      case actionsInvoice[0].id:
        printInvoiceInNewWindow(row.id);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <h4>Liste des factures</h4>
      <Grid
        columns={columns}
        dataSource={invoices}
        editing={editing}
        isGoupPanelVisible={true}
        dropDownButton={true}
        actions={[
          readOnlyActionsInvoice,
          readOnlyActionsInvoice,
          readOnlyActionsInvoice,
        ]}
        onActionClick={onActionClick}
      />
    </div>
  );
}
