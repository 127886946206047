const printInNewWindow = (quoteId) => {
  let frameElement = window.open(`quotes/Quote?id=${quoteId}`, "_blank"); //_self
  frameElement.addEventListener("load", function (e) {
    if (frameElement.document.contentType !== "text/html") frameElement.print();
  });
};

export const printInvoiceInNewWindow = (invoiceId) => {
  let frameElement = window.open(
    `invoices/InvoiceReport?id=${invoiceId}`,
    "_blank"
  ); //_self
  frameElement.addEventListener("load", function (e) {
    if (frameElement.document.contentType !== "text/html") frameElement.print();
  });
};

export default printInNewWindow;
