import React, { useRef, useState } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { stateInvoiceRender } from "./../utils/stateCell";
import convertToPersent from "../utils/convertToPersent";
import checkValueGreaterThan100 from "./../utils/checkValueGreaterThan100";
import invoiceService from "../../services/invoiceService";
import { toast } from "react-toastify";
import checkValueGreaterThanReste from "../utils/checkValueGraterThanReste";
import formatCfa from "./../utils/formatCfa";

function ModalDownPayment({ isPopupVisible, togglePopup, quote, redirect }) {
  const reste =
    quote.invoices.length > 0
      ? quote.invoices[quote.invoices.length - 1].reste
      : quote.totalHT;
  const refInput = useRef(null);
  const refSelect = useRef(null);
  const [downPayment, setDownPayment] = useState({
    totalDownPayement: 0,
    modeDiscount: "FCFA",
    reste: 0,
    isVisible: true,
  });

  const closeButtonOptions = {
    text: "Annuler",
    stylingMode: "contained",
    type: "normal",
    onClick: () => {
      setDownPayment((prevState) => ({
        ...prevState,
        totalDownPayement: 0,
        modeDiscount: "FCFA",
        reste: 0,
        isVisible: true,
      }));
      refInput.current.value = "";
      togglePopup();
    },
  };

  const createButtonOptions = {
    text: "Créer facture",
    stylingMode: "contained",
    type: "success",
    onClick: async () => {
      const down = {
        totalDownPayement: parseFloat(downPayment.totalDownPayement),
        modeDiscount: downPayment.modeDiscount,
      };
      const { data: invoice } = await invoiceService.createDownPaymentByQuoteId(
        quote.id,
        down
      );
      setDownPayment((prevState) => ({
        ...prevState,
        totalDownPayement: 0,
        modeDiscount: "FCFA",
        reste: 0,
        isVisible: true,
      }));
      refInput.current.value = "";
      togglePopup();
      toast.success("La facture a été crée.");
      redirect(invoice.id);
    },
  };

  const getFcfaValue = (value, reste) => {
    setDownPayment((prevState) => ({
      ...prevState,
      totalDownPayement: value,
      reste: reste - value,
      modeDiscount: "FCFA",
      isVisible: false,
    }));
  };

  function getPersentValue(value) {
    const totalDownPayement = convertToPersent(value) * quote.totalHT;
    setDownPayment((prevState) => ({
      ...prevState,
      totalDownPayement,
      reste: reste - totalDownPayement,
      modeDiscount: "%",
      isVisible: false,
    }));
  }

  const handleChange = ({ currentTarget: input }) => {
    if (input.value !== "") {
      if (
        downPayment.modeDiscount === "FCFA" &&
        downPayment.totalDownPayement >= 0
      ) {
        input.value = checkValueGreaterThanReste(input.value, reste);
        getFcfaValue(input.value, reste, setDownPayment);
      } else if (
        downPayment.modeDiscount === "%" &&
        downPayment.totalDownPayement >= 0
      ) {
        input.value = checkValueGreaterThan100(input.value, reste, quote);
        getPersentValue(input.value);
      }
    } else
      setDownPayment((prevState) => ({
        ...prevState,
        isVisible: true,
      }));
  };

  const handleChangeSelect = ({ currentTarget: input }) => {
    if (refInput.current.value !== "") {
      if (input.value === "%") {
        refInput.current.value = checkValueGreaterThan100(
          refInput.current.value,
          reste,
          quote
        );
        getPersentValue(refInput.current.value);
      } else if (input.value === "FCFA") {
        refInput.current.value = checkValueGreaterThanReste(
          refInput.current.value,
          reste
        );
        getFcfaValue(refInput.current.value, reste);
      }
    } else
      setDownPayment((prevState) => ({
        ...prevState,
        modeDiscount: input.value,
        isVisible: true,
      }));
  };

  const renderContent = () => {
    return (
      <div>
        <div className="row acompte">
          <strong>Facture d'acompte pour le devis {quote.number}</strong>
        </div>
        <div className="row reste">
          <div className="col-6 ">
            <strong>Reste à facturer</strong>{" "}
          </div>
          <div className="col-6">
            <strong>{formatCfa(reste)}</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-6">Montant du devis</div>
          <div className="col-6">{formatCfa(quote.totalHT)}</div>
        </div>
        <hr />
        {quote.invoices.map((i, index) => (
          <div key={index}>
            <div className="row">
              <div className="col-6">
                <strong>
                  Acompte #{index + 1} n° {i.number}
                </strong>
              </div>
              <div className="col-3">{formatCfa(i.totalHT)}</div>
              <div className="col-3">{stateInvoiceRender(i.state)}</div>
            </div>
            <hr />
          </div>
        ))}
        <div className="row">
          <div className="col-6"> Montant de l'acompte</div>
          <div className="col-3 form-group">
            <input
              type="number"
              min={0}
              className="form-control"
              onChange={handleChange}
              name="totalDownPayement"
              ref={refInput}
            />
          </div>
          <div className="col-3 form-group">
            <select
              name="modeDiscount"
              id=""
              className="form-control"
              onChange={handleChangeSelect}
              ref={refSelect}
            >
              <option>FCFA</option>
              <option>%</option>
            </select>
          </div>
        </div>
        <div className="row" hidden={downPayment.isVisible}>
          <div className="col-6">Total acompte</div>
          <div className="col-6">
            {formatCfa(downPayment.totalDownPayement)}
          </div>
        </div>

        <div className="row reste">
          <div className="col-6 ">
            <strong>Reste à facturer après deduction de l'acompte</strong>{" "}
          </div>
          <div className="col-6">
            <strong>{formatCfa(downPayment.reste)}</strong>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Popup
      id="popupdownpayment"
      visible={isPopupVisible}
      closeOnOutsideClick={false}
      dragEnabled={false}
      showCloseButton={false}
      onHiding={togglePopup}
      title="Facture d'acompte"
      width={680}
      height="auto"
      position="top"
      showTitle={true}
      contentRender={renderContent}
    >
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={createButtonOptions}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        options={closeButtonOptions}
      />
    </Popup>
  );
}

export default ModalDownPayment;
