import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import errors from "./utils/errors";
import Details from "./Details";
import articleService from "../services/articleService";
import unitService from "../services/unitService";
import getNumber, {
  getDetailArticles,
  updateSection,
  convertToNumber,
  calculTotalTTC,
} from "./utils/number";
import {
  deleteAllSectionWithArticles,
  deleteSection,
  deleteArticle,
  deleteItem,
} from "./utils/removeDetail";
import Modal from "./common/Modal";
import quoteService from "../services/quoteService";
import detailSectionService from "../services/detailSectionService";
import customerService from "../services/customerService";
import { actionsState } from "./utils/actions";
import { menuItems, menuItemsWithoutSection } from "./utils/menuItems";
import { insertDown, insertUp } from "./utils/insertDetail";
import populateCustomerInfo from "./utils/populateCustomerInfo";
import { formattingISODate } from "./utils/formattingISODate";
import populateUnitId from "./utils/populateUnit";
import { toast } from "react-toastify";
import printInNewWindow from "./utils/printInNewWindow";
import { enumState } from "./utils/state";
import InvoiceQuoteGeneralInfo from "./InvoiceQuoteGeneralInfo";
import QuoteAreaTotal from "./QuoteAreaTotal";
import ActionsInvoiceQuoteForm from "./common/ActionsInvoiceQuoteForm";
import Input from "./common/Input";
import Condtitions from "./Conditions";
import activityService from "../services/activityService";
import convertToFloat from "./utils/convertToFloat";
import convertToPersent from "./utils/convertToPersent";
import enterpriseService from "../services/enterpriseService";

const defaultNotes = process.env.REACT_APP_NOTES_DEFAULT_VALUE;
class QuoteForm extends Form {
  state = {
    data: {
      number: "",
      customerId: "",
      infoCustomer: {
        isVisibleCustomer: true,
        name: "",
        adresses: "",
      },
      discount: "",
      rateDiscount: "",
      totalHT: "",
      totalTTC: "",
      tva: "",
      rateTVA: "",
      projectName: "",
      notes: defaultNotes,
      quoteDate: "",
      isCanceled: "",
      executionTime: "",
      totalInvoice: "",
      modeDiscount: "FCFA",
      details: [],
      conditions: [],
    },
    errors: {},
    articles: [],
    units: [],
    detailSections: [],
    customers: [],
    activities: [],
    modalOptions: {
      isPopupVisible: false,
      width: 500,
      height: 222,
      message: "",
      toolbarItems: [],
    },
  };

  schema = {
    id: Joi.any().optional(),
    number: Joi.string().required().label("Numéro devis").error(errors),
    customerId: Joi.any().required().label("Client").error(errors),
    projectName: Joi.string().required().label("Nom du project").error(errors),
    quoteDate: Joi.string().required().label("Date création").error(errors),
    notes: Joi.any().optional().label("Notes").error(errors),
    conditions: Joi.any().optional().label("Conditions").error(errors),
    modeDiscount: Joi.any().optional().label("mode discount").error(errors),
    discount: Joi.any().optional().label("Discount").error(errors),
    rateDiscount: Joi.any().optional(),
    details: Joi.any().optional(),
    isCanceled: Joi.any().optional(),
    totalHT: Joi.any().optional(),
    totalTTC: Joi.any().optional(),
    totalInvoice: Joi.any().optional(),
    tva: Joi.any().optional(),
    rateTVA: Joi.any().optional(),
    state: Joi.any().optional(),
    infoCustomer: Joi.any().optional(),
    executionTime: Joi.any().optional(),
  };

  async populateQuote() {
    try {
      const quoteId = this.props.match.params.id;
      if (quoteId === "new") {
        const data = {
          number: "",
          customerId: "",
          infoCustomer: {
            isVisibleCustomer: true,
            name: "",
            adresses: "",
          },
          discount: "",
          rateDiscount: "",
          totalHT: "",
          totalTTC: "",
          tva: "",
          rateTVA: "",
          projectName: "",
          notes: defaultNotes,
          quoteDate: "",
          isCanceled: "",
          executionTime: "",
          totalInvoice: "",
          modeDiscount: "FCFA",
          details: [],
          conditions: [],
        };
        const { data: number } = await quoteService.getNewQuoteNumber();
        data.number = number;
        const { data: rate } = await enterpriseService.getRateTVA();
        data.rateTVA = rate;
        this.setState({ data });
        return;
      }
      const { data } = await quoteService.getQuote(parseInt(quoteId));
      data.quoteDate = formattingISODate(data.quoteDate);
      const infoCustomer = {
        isVisibleCustomer: false,
        name: "",
        adresses: "",
      };
      data.infoCustomer = populateCustomerInfo(
        data.customerId,
        infoCustomer,
        this.state.customers
      );
      populateUnitId(data.details, this.state.articles);
      this.setState({ data });
    } catch (ex) {
      alert(`populateQuote ${ex}`);
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateArticlesUnitsCustomerDetailSections() {
    const { data: articles } = await articleService.getArticles();
    const { data: units } = await unitService.getUnits();
    const { data: detailSections } =
      await detailSectionService.getDetailSections();
    const { data: customers } = await customerService.getCustomers();
    const { data: activities } = await activityService.getActivities();

    this.setState({ articles, units, detailSections, customers, activities });
    // let sources = [
    //   articleService.getArticles(),
    //   unitService.getUnits(),
    //   detailSectionService.getDetailSections(),
    //   customerService.getCustomers(),
    //   activityService.getActivities(),
    // ];
    // http
    //   .all(sources)
    //   .then(
    //     http.spread((...responses) => {
    //       this.setState({
    //         articles: responses[0].data,
    //         units: responses[1].data,
    //         detailSections: responses[2].data,
    //         customers: responses[3].data,
    //         activities: responses[4].data,
    //       });
    //     })
    //   )
    // .catch((ex) => {
    //   alert("populateArticlesUnitsCustomerDetailSections", ex);
    //   if (ex.response && ex.response.status === 404)
    //     this.props.history.push("/not-found");
    // });
  }

  handleChangeDetail = ({ currentTarget: input }, detail) => {
    const data = { ...this.state.data };
    data.details = [...this.state.data.details];
    const index = data.details.indexOf(detail);
    data.details[index] = { ...detail };
    data.details[index][input.name] = input.value;

    if (input.name === "quantity" && data.details[index].price !== "") {
      data.details[index]["total"] = input.value * data.details[index].price;
      updateSection(detail, data);
    }

    if (input.name === "price" && data.details[index].quantity !== "") {
      data.details[index]["total"] = input.value * data.details[index].quantity;
      updateSection(detail, data);
    }

    this.setState({ data });
  };

  handleChangeDataListDetail = (
    { currentTarget: input },
    options = [],
    detail
  ) => {
    const item = options.find((d) => d.name.trim() === input.value.trim());
    const value = item !== undefined ? item.id : input.value;
    const data = { ...this.state.data };
    data.details = [...this.state.data.details];
    const index = data.details.indexOf(detail);
    data.details[index] = { ...detail };
    data.details[index][input.name] = value.toString();

    if (input.name === "articleId" && item) {
      const unitId = item.unit.id;
      data.details[index]["price"] = item.price;
      data.details[index]["total"] = data.details[index].quantity * item.price;
      data.details[index]["unitId"] = unitId;
      updateSection(detail, data);
    }

    if (input.name === "detailSectionId") {
      if (!item) {
        data.details[index]["libelleDetailSectionId"] = input.value.trim();
      }
    }
    this.setState({ data });
  };

  addDetail = (isSection) => {
    const data = { ...this.state.data };
    let lastItem = data.details[data.details.length - 1];
    const value = getNumber(data.details, isSection, lastItem);
    data.details.push({
      isSection,
      number: value.number,
      numberSection: value.numberSection,
      detailSectionId: "",
      articleId: "",
      unitId: "",
      quantity: "1",
      price: "",
      total: "",
    });
    this.setState({ data });
  };

  renderModal = (message, toolbarItems, width, height) => {
    const modalOptions = { ...this.state.modalOptions };
    modalOptions.isPopupVisible = true;
    modalOptions.width = width;
    modalOptions.height = height;
    modalOptions.message = message;
    modalOptions.toolbarItems = toolbarItems;
    this.setState({ modalOptions });
  };

  togglePopup = () => {
    const modalOptions = { ...this.state.modalOptions };
    modalOptions.isPopupVisible = !this.state.modalOptions.isPopupVisible;

    this.setState({ modalOptions });
  };

  removeDetail = (detail, data, index) => {
    data.details = data.details.filter((d) => d.number !== detail.number);
    if (detail.isSection) {
      var artilces = getDetailArticles(data, detail);
      if (artilces.length > 0)
        this.renderModal(
          "Comment souhaitez-vous supprimer cette section?",
          [
            {
              options: {
                text: "Supprimer toute la section",
                stylingMode: "contained",
                type: "danger",
                onClick: () => {
                  deleteAllSectionWithArticles(data, detail);
                  this.ClosePopupChangeState(data);
                },
              },
            },
            {
              options: {
                text: "Supprimer et conserver le contenu",
                stylingMode: "contained",
                type: "default",
                onClick: () => {
                  deleteSection(data, detail, index);
                  this.ClosePopupChangeState(data);
                },
              },
            },
          ],
          700,
          222
        );
      else
        this.renderModal(
          "Confirmer la suppression?",
          [
            {
              options: {
                text: "Oui, supprimer",
                stylingMode: "contained",
                type: "danger",
                onClick: () => {
                  deleteItem(index, data);
                  this.ClosePopupChangeState(data);
                },
              },
            },
          ],
          500,
          222
        );
    } else
      this.renderModal(
        "Confirmer la suppression?",
        [
          {
            options: {
              text: "Oui, supprimer",
              stylingMode: "contained",
              type: "danger",
              onClick: () => {
                deleteArticle(detail, data, index);
                this.ClosePopupChangeState(data);
              },
            },
          },
        ],
        500,
        222
      );
  };

  clickAction = ({ itemData }, detail) => {
    const data = { ...this.state.data };
    data.details = [...this.state.data.details];
    const index = data.details.indexOf(detail);
    data.details[index] = { ...detail };
    if (itemData.id !== undefined) {
      const menuItemss =
        !detail.isSection && detail.numberSection !== 0
          ? menuItemsWithoutSection
          : menuItems;
      if (itemData.id === menuItemss[0].items[2].id) {
        this.removeDetail(detail, data, index);
        return;
      } else if (itemData.id === menuItemss[0].items[0].items[0].id)
        insertUp(false, index, detail, data);
      else if (itemData.id === menuItemss[0].items[1].items[0].id)
        insertDown(false, index, detail, data);
      else if (itemData.id === menuItemss[0].items[0].items[1].id)
        insertUp(true, index, detail, data);
      else if (itemData.id === menuItemss[0].items[1].items[1].id)
        insertDown(true, index, detail, data);
    }

    this.setState({ data });
  };

  ClosePopupChangeState(data) {
    this.togglePopup();
    this.setState({ data });
  }

  async componentDidMount() {
    await this.populateArticlesUnitsCustomerDetailSections();
    await this.populateQuote();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.populateArticlesUnitsCustomerDetailSections();
      await this.populateQuote();
    }
  }

  doSubmit = async () => {
    try {
      const { data: quote } = await quoteService.saveQuote(
        this.convertQuoteProperties()
      );
      toast.success("Le devis a été enregistré");
      if (this.props.match.params.id === "new") {
        window.location = `/quote/${quote.id}`;
        return;
      }
      window.location = `/quote/${quote.id}`;
      //this.props.history.push(`/quote/${quote.id}`);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) toast.error(ex.response);
    }
  };

  convertQuoteProperties = () => {
    const quote = { ...this.state.data };
    quote.customerId = convertToNumber(quote.customerId);
    quote.discount = convertToNumber(quote.discount);
    quote.totalHT = convertToNumber(quote.totalHT);
    quote.totalTTC = convertToNumber(quote.totalTTC);
    quote.rateTVA = convertToNumber(quote.rateTVA);
    quote.tva = convertToNumber(quote.tva);
    quote.isCanceled = false;
    quote.rateDiscount = convertToNumber(quote.rateDiscount);
    for (let i = 0; i < quote.details.length; i++) {
      quote.details[i] = { ...quote.details[i] };
      quote.details[i].number = quote.details[i].number.toString();
      quote.details[i].numberSection =
        quote.details[i].numberSection.toString();
      quote.details[i].articleId = convertToNumber(quote.details[i].articleId);
      quote.details[i].unitId = convertToNumber(quote.details[i].unitId);
      // if (isNaN(quote.details[i].detailSectionId)) {
      if (quote.details[i].libelleDetailSectionId) {
        quote.details[i].detailSectionId = 0;
        quote.details[i].detailSection = {
          name: quote.details[i].libelleDetailSectionId,
        };
      } else
        quote.details[i].detailSectionId = convertToNumber(
          quote.details[i].detailSectionId
        );

      quote.details[i].total = convertToNumber(quote.details[i].total);
      quote.details[i].price = convertToNumber(quote.details[i].price);
      quote.details[i].quantity = convertToNumber(quote.details[i].quantity);
    }

    return quote;
  };

  handleClick = () => {
    const data = { ...this.state.data };
    data.infoCustomer.isVisibleCustomer = true;
    data.customerId = "";

    this.setState({ data });
  };

  handleChangeQuoteState = async (state) => {
    const quoteId = this.props.match.params.id;
    const data = { ...this.state.data };
    await quoteService.changeQuoteState(quoteId, state);
    data.state = state;
    this.setState({ data });
    toast.success("Le statut du devis a changé");
  };

  onActionClick = ({ itemData: action }) => {
    switch (action) {
      case actionsState[0]:
        this.handleChangeQuoteState(parseInt(enumState.Draft));
        break;
      case actionsState[1]:
        this.handleChangeQuoteState(parseInt(enumState.final));
        break;
      case actionsState[2]:
        this.handleChangeQuoteState(parseInt(enumState.Accepted));
        break;
      case actionsState[3]:
        this.handleChangeQuoteState(parseInt(enumState.Refused));
        break;

      default:
        break;
    }
  };

  handleCanceled = () => this.props.history.push("/");

  handleChangeCondition = ({ currentTarget: input }, condition) => {
    const data = { ...this.state.data };
    data.conditions = [...this.state.data.conditions];
    const index = data.conditions.indexOf(condition);
    data.conditions[index] = { ...condition };
    data.conditions[index][input.name] = input.value;
    if (
      (input.name === "modePayment" || input.name === "montant") &&
      (input.value === "%" || data.conditions[index]["modePayment"] === "%")
    ) {
      data.conditions[index]["total"] =
        convertToPersent(data.conditions[index]["montant"]) *
        convertToFloat(data.totalHT);
    }
    this.setState({ data });
  };

  addCondition = () => {
    const data = { ...this.state.data };
    data.conditions.push({
      activityId: "",
      montant: "",
      total: "",
      modePayment: "%",
    });

    this.setState({ data });
  };

  removeCondition = (condition) => {
    const data = { ...this.state.data };
    data.conditions = [...this.state.data.conditions];
    data.conditions = data.conditions.filter((c) => c !== condition);
    this.setState({ data });
  };

  handleChangeRateTVA = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data.rateTVA = parseFloat(input.value);
    calculTotalTTC(data);
    this.setState({ data });
  };

  render() {
    const {
      modalOptions,
      customers,
      detailSections,
      articles,
      units,
      activities,
      data,
    } = this.state;
    const quoteId = this.props.match.params.id;
    return (
      <div className="Quote-form">
        <Modal
          isPopupVisible={modalOptions.isPopupVisible}
          togglePopup={this.togglePopup}
          width={modalOptions.width}
          height={modalOptions.height}
          message={modalOptions.message}
          toolbarItems={modalOptions.toolbarItems}
        />
        <InvoiceQuoteGeneralInfo
          NumberLibelle="Devis N°"
          createdDate="quoteDate"
          data={data}
          customers={customers}
          onHandleChangeDataList={this.handleChangeDataList}
          onhandleClick={this.handleClick}
          onHandleChange={this.handleChange}
        />
        <div className="w-25">
          <Input
            onChange={this.handleChange}
            value={data["executionTime"]}
            id={"executionTime"}
            name={"executionTime"}
            placeholder="Delai d'execution en jour"
            type="number"
          />
        </div>
        <Details
          details={this.state.data.details}
          detailSections={detailSections}
          articles={articles}
          units={units}
          onHandleChangeDataListDetail={this.handleChangeDataListDetail}
          onHandleChangeDetail={this.handleChangeDetail}
          onClickAction={this.clickAction}
          onAddDetail={this.addDetail}
        />
        <QuoteAreaTotal
          onHandleChange={this.handleChange}
          data={data}
          onHandleChangeRateTVA={this.handleChangeRateTVA}
        />
        <div>
          <button
            type="button"
            className="btn btn-link"
            onClick={this.addCondition}
          >
            Ajouter une condition
          </button>
        </div>
        <Condtitions
          activities={activities}
          condtions={data.conditions || []}
          onHandleChangeCondition={this.handleChangeCondition}
          onRemoveCondition={this.removeCondition}
        />

        {this.renderTextarea("notes", "Notes")}

        <ActionsInvoiceQuoteForm
          quoteId={quoteId}
          onValidate={this.validate}
          onHandleSubmit={this.handleSubmit}
          OnPrintInNewWindow={printInNewWindow}
          actions={actionsState}
          onActionClick={this.onActionClick}
          onHandleCanceled={this.handleCanceled}
        />
      </div>
    );
  }
}

export default QuoteForm;
