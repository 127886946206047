import {
  getDetailArticles,
  getSousSectionByNumberSection,
  calculTotalTTC,
  splitNumber,
  updateSection,
} from "./number";

export function deleteArticle(detail, data, index) {
  if (detail.numberSection !== 0) {
    deleteSectionArticle(data, detail, index);
    updateSection(detail, data);
  } else {
    deleteItem(index, data);
  }
}

export function deleteSectionArticle(data, detail, index) {
  const articles = getSousSectionByNumberSection(data, detail);
  const lastArticle = articles[articles.length - 1];
  const indexLastArticle = data.details.indexOf(lastArticle);
  for (let i = index; i <= indexLastArticle; i++) {
    data.details[i] = { ...data.details[i] };
    data.details[i].number = parseFloat(
      (data.details[i].number - 0.1).toFixed(1)
    );
  }
}

export function deleteItem(index, data) {
  for (let i = index; i < data.details.length; i++) {
    data.details[i] = { ...data.details[i] };
    splitNumber(data, i);
  }
  calculTotalTTC(data);
}

export function deleteSection(data, detail, index) {
  const articles = getDetailArticles(data, detail);
  const lastArticle = articles[articles.length - 1];
  const indexLastArticle = data.details.indexOf(lastArticle);
  for (let i = index; i <= indexLastArticle; i++) {
    data.details[i] = { ...data.details[i] };
    if (data.details[i].numberSection === detail.number) {
      const s1 = data.details[i].number.toString().split(".");
      data.details[i].number =
        s1.reduce((a, c) => parseInt(a) + parseInt(c)) - 1;
      data.details[i].numberSection = 0;
    }
  }
  if (data.details[indexLastArticle + 1]) {
    const addValue =
      data.details[indexLastArticle].number +
      1 -
      data.details[indexLastArticle + 1].number;
    for (let i = indexLastArticle + 1; i < data.details.length; i++) {
      const valueSplit1 = data.details[i].number.toString().split(".");
      data.details[i].number =
        valueSplit1.length === 1
          ? data.details[i].number + addValue
          : parseFloat((data.details[i].number + addValue).toFixed(1));
      data.details[i].numberSection =
        data.details[i].numberSection !== 0
          ? data.details[i].numberSection + addValue
          : 0;
    }
  }
  calculTotalTTC(data);
}

export function deleteAllSectionWithArticles(data, detail) {
  const articles = getDetailArticles(data, detail);
  const lastArticle = articles[articles.length - 1];
  const indexFirstArticle = data.details.indexOf(articles[0]);
  const indexLastArticle = data.details.indexOf(lastArticle);

  for (let i = indexLastArticle + 1; i < data.details.length; i++) {
    data.details[i] = { ...data.details[i] };
    splitNumber(data, i);
  }
  data.details.splice(
    indexFirstArticle,
    indexLastArticle + 1 - indexFirstArticle
  );

  calculTotalTTC(data);
}
