export function hiddenDiscount(data) {
  return (
    data.rateDiscount === "" ||
    data.rateDiscount === "0" ||
    data.rateDiscount === 0
  );
}

export function hiddenTotal(data) {
  return data.totalInvoice === data.totalHT;
}
