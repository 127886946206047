import React from "react";
import Discount from "./common/Discount";
import { hiddenDiscount, hiddenTotal } from "./utils/hidden";
import formatCfa from "./utils/formatCfa";

const QuoteAreaTotal = (props) => {
  const { onHandleChange, onHandleChangeRateTVA, data } = props;
  return (
    <div className="row mt-3">
      <div className="col-7"></div>
      <div className="col-5">
        <Discount onHandleChange={onHandleChange} data={data} />
        <div className="cards">
          <div className="row subtotal" hidden={hiddenTotal(data)}>
            <div className="col-6 left">
              <strong>Total</strong>
            </div>
            <div className="col-6 rigth">
              <strong>{formatCfa(data.totalInvoice)}</strong>
            </div>
          </div>
          <div className="row subtotal" hidden={hiddenDiscount(data)}>
            <div className="col-6 left">
              <strong>Total remise</strong>
            </div>
            <div className="col-6 rigth">
              <strong>{formatCfa(data.discount)}</strong>
            </div>
          </div>
          <div className="row subtotal">
            <div className="col-6 left">
              <strong>Total HT</strong>
            </div>
            <div className="col-6 rigth">
              <strong>{formatCfa(data.totalHT)}</strong>
            </div>
          </div>
          <div className="row subtotal">
            <div className="col-6 left ">
              <div className="row">
                <div className="col-6 tva">
                  <strong>TVA</strong>
                </div>
                <div className="col-6 form-group tva">
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    name="rateTVA"
                    value={data["rateTVA"]}
                    onChange={onHandleChangeRateTVA}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 rigth">
              <strong>{formatCfa(data.tva)}</strong>
            </div>
          </div>
          <div className="row subtotal">
            <div className="col-6 left">
              <strong>Total TTC</strong>
            </div>
            <div className="col-6 rigth">
              <strong>{formatCfa(data.totalTTC)}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteAreaTotal;
