import React from "react";
import DataList from "./DataList";
import { Menu } from "devextreme-react/menu";
import { menuItems } from "../utils/menuItems";
import formatCfa from "./../utils/formatCfa";

const Section = ({
  detail: data,
  onRemoveDetail,
  onClickAction,
  sections,
  onChangeDataList,
}) => {
  return (
    <div className="row section">
      <div className="col-1">{data.number} </div>
      <div className="col-6">
        <DataList
          autoFocus
          id="detailSectionId"
          list="sections"
          name="detailSectionId"
          data={sections}
          placeholder="Titre section"
          value={data["detailSectionId"]}
          onChange={(e) => onChangeDataList(e, sections, data)}
        />
      </div>
      <div className="col-4 total-sous-section">
        <span className="total">
          Sous-total: <strong>{formatCfa(data.total)}</strong>
        </span>
      </div>
      <div className="col-1">
        <Menu
          items={menuItems}
          displayExpr="name"
          onItemClick={(e) => onClickAction(e, data)}
        />
      </div>
    </div>
  );
};

export default Section;
