import React, { Component } from "react";
import Input from "./Input";
import Select from "./Select";
import Joi from "joi-browser";
import DataList from "./DataList";
import Textarea from "./Textarea";
import { calculTotalTTC, getDiscountValue } from "../utils/number";
import populateCustomerInfo from "../utils/populateCustomerInfo";
import SimpleDataList from "./SimpleDataList";
import DataListString from "./DataListString";
class Form extends Component {
  state = {
    data: {},
    errors: {},
  };
  validateProperty = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;
    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    if (errors) {
      this.setState({ errors: errors || {} });
      return;
    }

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input.name, input.value);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.changeTotalHt(input, data);
    this.setState({ data, errors });
  };

  handleChangeDataList = ({ currentTarget: input }, options) => {
    const item = options.find((d) => d.name.trim() === input.value.trim());
    const value = item !== undefined ? item.id : input.value;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input.name, value.toString());
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = value.toString();
    this.changeCustomer(input, value);
    this.setState({ data, errors });
  };

  handleChangeSimpleDataList = ({ currentTarget: input }, options) => {
    options = options.filter((o) => o !== null);
    const item = options.find((d) => d.trim() === input.value.trim());
    const value = item !== undefined ? item : input.value;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input.name, value.toString());
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = value.toString();
    this.setState({ data, errors });
  };

  changeTotalHt(input, data) {
    if (input.name === "modeDiscount") {
      data["discount"] = data["rateDiscount"] = 0;
      calculTotalTTC(data);
    } else if (input.name === "rateDiscount") {
      getDiscountValue(data, input.value);
      calculTotalTTC(data);
    }
  }

  changeCustomer(input, value) {
    if (input.name === "customerId" && typeof value === "number") {
      const data = { ...this.state.data };
      data.infoCustomer = populateCustomerInfo(
        value,
        data.infoCustomer,
        this.state.customers
      );
    }
  }

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderDataList(name, label, options, list) {
    const { data, errors } = this.state;
    return (
      <DataList
        id={name}
        list={list}
        name={name}
        data={options}
        label={label}
        value={data[name]}
        onChange={(e) => this.handleChangeDataList(e, options)}
        error={errors[name]}
      />
    );
  }
  renderDataListString(name, label, options, list) {
    const { data, errors } = this.state;
    return (
      <DataListString
        id={name}
        list={list}
        name={name}
        data={options}
        label={label}
        value={data[name]}
        onChange={(e) => this.handleChangeDataList(e, options)}
        error={errors[name]}
      />
    );
  }

  renderSimpleDataList(name, label, options, list) {
    const { data, errors } = this.state;
    return (
      <SimpleDataList
        id={name}
        list={list}
        name={name}
        data={options}
        label={label}
        value={data[name]}
        onChange={(e) => this.handleChangeSimpleDataList(e, options)}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        onChange={this.handleChange}
        value={data[name]}
        id={name}
        name={name}
        label={label}
        type={type}
        error={errors[name]}
      />
    );
  }

  renderTextarea(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Textarea
        onChange={this.handleChange}
        value={data[name]}
        id={name}
        name={name}
        label={label}
        type={type}
        error={errors[name]}
      />
    );
  }
  renderSelect(name, label, options) {
    const { errors, data } = this.state;
    return (
      <Select
        name={name}
        id={name}
        label={label}
        value={data[name]}
        data={options}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }
}

export default Form;
