import React from "react";
import Section from "./common/Section";
import DetailsQuote from "./DetailsQuote";

const Details = (props) => {
  const {
    details,
    detailSections,
    articles,
    units,
    onHandleChangeDataListDetail,
    onHandleChangeDetail,
    onClickAction,
    onAddDetail,
  } = props;

  return (
    <React.Fragment>
      <div className="row thead-detail mt-3">
        <div className="col-1">N°</div>
        <div className="col-4">Désignation</div>
        <div className="col-1">Unité</div>
        <div className="col-1">Quantité</div>
        <div className="col-2">Prix U</div>
        <div className="col-2">Montant</div>
        <div className="col-1"></div>
      </div>
      <div id="list-details">
        {details.map((detail) =>
          detail.isSection ? (
            <Section
              key={detail.number}
              detail={detail}
              sections={detailSections}
              onChangeDataList={onHandleChangeDataListDetail}
              onClickAction={onClickAction}
            />
          ) : (
            <DetailsQuote
              key={detail.number}
              detail={detail}
              articles={articles}
              units={units}
              onChange={onHandleChangeDetail}
              onChangeDataList={onHandleChangeDataListDetail}
              onClickAction={onClickAction}
            />
          )
        )}
      </div>
      <button
        className="btn btn-outline-primary mt-2 mr-2"
        onClick={() => onAddDetail(true)}
      >
        Section
      </button>
      <button
        className="btn-add btn btn-outline-primary mt-2 "
        onClick={() => onAddDetail(false)}
      >
        Article
      </button>
    </React.Fragment>
  );
};

export default Details;
