import React from "react";
import ko from "knockout";
import "devexpress-reporting/dx-webdocumentviewer";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import { AsyncExportApproach } from "devexpress-reporting/scopes/reporting-viewer-settings";
import "./InvoiceReport.css";
import authService from "./api-authorization/AuthorizeService";

class ReportViewer extends React.Component {
  constructor(props) {
    super(props);

    this.reportUrl = ko.observable("InvoiceReport?quoteId=28");
    this.requestOptions = {
      host: "https://localhost:44351/",
      // Use this line for the ASP.NET MVC backend.
      //invokeAction: "/WebDocumentViewer/Invoke"
      // Use this line for the ASP.NET Core backend
      invokeAction: "DXXRDV",
    };
  }
  render() {
    return <div ref="viewer" data-bind="dxReportViewer: $data"></div>;
  }
  async componentDidMount() {
    this.getToken();

    ko.applyBindings(
      {
        reportUrl: this.reportUrl,
        requestOptions: this.requestOptions,
      },
      this.refs.viewer
    );
  }
  componentWillUnmount() {
    ko.cleanNode(this.refs.viewer);
  }

  async getToken() {
    const token = await authService.getAccessToken();
    ajaxSetup.ajaxSettings = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    AsyncExportApproach(true);
  }
}

function InvoiceReport() {
  const printInNewWindow = () => {
    var frameElement = window.open("quotes/Print?id=28", "_blank"); //_self
    frameElement.addEventListener("load", function (e) {
      if (frameElement.document.contentType !== "text/html")
        frameElement.print();
    });
  };

  return (
    // <div style={{ width: "100%", height: "1000px" }}>
    //   <ReportViewer />
    // </div>
    <button style={{ margin: "5px" }} onClick={printInNewWindow}>
      Print a report in a new tab
    </button>
  );
}

export default InvoiceReport;
