import React from "react";
import DropDownButton from "devextreme-react/drop-down-button";
import DataGrid, {
  Editing,
  Column,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
  FilterRow,
  FilterPanel,
  Export,
  ColumnChooser,
  FilterBuilderPopup,
  Selection,
  LoadPanel,
  RequiredRule,
  Grouping,
  GroupPanel,
  Lookup,
  Popup,
  Position,
  Summary,
  GroupItem,
  TotalItem,
  ColumnFixing,
  Button,
} from "devextreme-react/data-grid";
import choiseActions from "../utils/choiseActions";

const Grid = ({
  columns,
  buttons,
  editing,
  onToolbarPreparing,
  onRowRemoved,
  dataSource,
  refGrid,
  isGoupPanelVisible,
  defaultPageSize,
  exportenabled,
  allowSelection,
  columnAutoWidth,
  columnMinWidth,
  actions,
  dropDownButton,
  onActionClick,
  autoExpandAll,
  rowAlternationEnabled,
  summary,
}) => {
  const filterPanel = {
    clearFilter: "Enlever le filtre",
    createFilter: "Filtre par une colonne",
    filterEnabledHint: "Activer le filtre",
  };

  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr="id"
      columnHidingEnabled={true}
      columnAutoWidth={columnAutoWidth === undefined ? true : columnAutoWidth}
      columnMinWidth={columnMinWidth || undefined}
      showBorders={true}
      showRowLines={true}
      focusedRowEnabled={false}
      rowAlternationEnabled={rowAlternationEnabled === false ? false : true}
      onToolbarPreparing={onToolbarPreparing || null}
      onRowRemoved={onRowRemoved || null}
      noDataText="Pas de données"
      wordWrapEnabled={false}
      ref={refGrid}
    >
      <ColumnFixing enabled={true} />
      <Export
        enabled={exportenabled != null ? false : true}
        allowExportSelectedData={true}
        texts={{
          exportAll: "Exporter toutes les données",
          exportSelectedRows: "Exporter les lignes selectionnées",
          exportTo: "Exporter",
        }}
      />
      <LoadPanel
        enabled="auto"
        height={90}
        indicatorSrc=""
        shading={false}
        shadingColor=""
        showIndicator={true}
        showPane={true}
        text="Chargement..."
        width={200}
      />
      <FilterBuilderPopup
        position={{ of: window, at: "top", my: "top" }}
        height={500}
      />
      <FilterRow
        visible={true}
        applyFilter="auto"
        applyFilterText="Appliquer filtrer"
        betweenEndText="Fin"
        betweenStartText="Début"
        operationDescriptions={{
          between: "Entre",
          contains: "Contient",
          endsWith: "Se termine par",
          equal: "Egal à",
          greaterThan: "Plus grand que",
          greaterThanOrEqual: "Plus grand ou égal à",
          lessThan: "Moins que",
          lessThanOrEqual: "Inférieur ou égal à",
          notContains: "Ne contient pas",
          notEqual: "N'est pas égal",
          startsWith: "Commence avec",
        }}
        resetOperationText="Réinitialiser"
      />
      <FilterPanel visible={true} texts={filterPanel} />
      <HeaderFilter
        visible={true}
        allowSearch={true}
        texts={{
          cancel: "Annuler",
          emptyValue: "",
          ok: "Ok",
        }}
      />
      {isGoupPanelVisible ? (
        <GroupPanel
          visible={true}
          emptyPanelText="Faites glisser une en-tête de colonne ici pour regrouper par cette colonne"
        />
      ) : null}
      {autoExpandAll ? <Grouping autoExpandAll={autoExpandAll} /> : null}
      <SearchPanel visible={true} width={240} placeholder="Rechercher..." />
      <ColumnChooser
        allowSearch={true}
        enabled={true}
        height={260}
        mode="select"
        title="Choix des colonnes"
      />
      {allowSelection === undefined ? (
        <Selection
          allowSelectAll={true}
          deferred={false}
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="onClick"
        />
      ) : null}

      {columns.map((c) => (
        <Column
          key={c.dataField}
          dataField={c.dataField}
          caption={c.caption}
          dataType={c.dataType}
          editCellComponent={c.editCellComponent || null}
          cellTemplate={c.cellTemplate || null}
          cellRender={c.cellRender || null}
          format={c.dataType === "date" ? "dd/MM/yyy" : c.format}
          width={c.width !== undefined ? c.width : undefined}
          minWidth={c.minWidth !== undefined ? c.minWidth : undefined}
          fixed={c.fixed ? true : false}
          allowSorting={c.allowSorting === null ? true : c.allowSorting}
          allowFiltering={c.allowFiltering === null ? true : c.allowFiltering}
          groupIndex={c.groupIndex}
          groupCellTemplate={c.groupCellTemplate}
        >
          {c.requiredRule ? <RequiredRule /> : null}
          {c.dataSource ? (
            <Lookup
              dataSource={c.dataSource}
              displayExpr={c.displayExpr}
              valueExpr={c.valueExpr}
            />
          ) : null}
        </Column>
      ))}
      {buttons ? <Column type="buttons" buttons={buttons} /> : null}
      {dropDownButton ? (
        <Column type="buttons" width={100}>
          <Button
            render={(ex) => (
              <DropDownButton
                stylingMode="text"
                icon="bulletlist"
                items={choiseActions(ex.data, actions)}
                keyExpr="id"
                displayExpr="text"
                dropDownOptions={{ width: 210 }}
                onItemClick={(e, exx) => onActionClick(e, ex)}
              />
            )}
          />
        </Column>
      ) : null}
      <Editing
        mode={editing.mode}
        allowAdding={editing.allowAdding}
        allowDeleting={editing.allowDeleting}
        allowUpdating={editing.allowUpdating}
        texts={{
          addRow: "Ajouter un enregistrement",
          cancelAllChanges: "Annuler les modifications",
          cancelRowChanges: "Annuler",
          confirmDeleteMessage:
            "Êtes-vous sûr de vouloir supprimer cet enregistrement?",
          confirmDeleteTitle: "",
          deleteRow: "Supprimer",
          editRow: "Modifier",
          saveAllChanges: "Sauvegarder les modifications",
          saveRowChanges: "Enregistrer",
          undeleteRow: "Annuler la suppression",
          validationCancelChanges: "Annuler les modifications",
        }}
      >
        {editing.mode === "popup" ? (
          <Popup
            title={editing.title}
            showTitle={true}
            width={700}
            height={400}
          >
            <Position my="top" at="top" of={window} />
          </Popup>
        ) : null}
      </Editing>
      <Pager
        allowedPageSizes={[10, 15, 20, 100]}
        infoText="Page {0} sur {1} ({2} élements)"
        showInfo={true}
        showNavigationButtons={true}
        showPageSizeSelector={true}
        visible={true}
      />
      <Paging
        defaultPageSize={defaultPageSize || 10}
        enabled={true}
        defaultPageIndex={0}
      />

      {isGoupPanelVisible ? (
        <Summary>
          <GroupItem
            column="Id"
            summaryType="count"
            displayFormat="Nombre d'impayé: {0}"
          />
          {summary ? (
            <GroupItem
              column={summary.column}
              summaryType={summary.type}
              valueFormat={summary.format}
              displayFormat="Total impayé: {0}"
              alignByColumn={false}
              showInGroupFooter={false}
            />
          ) : null}
          {summary ? (
            <TotalItem
              column={summary.column}
              summaryType={summary.type}
              valueFormat={summary.format}
              displayFormat="Total général impayé: {0}"
            />
          ) : null}
        </Summary>
      ) : null}
    </DataGrid>
  );
};

export default Grid;
