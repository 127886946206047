import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import customerService from "../services/customerService";
import Grid from "./common/Grid";

export default function Customers(props) {
  const [customers, setCustomers] = useState([]);
  const columns = [
    { dataField: "number", caption: "Numéro" },
    { dataField: "name", caption: "Libelle" },
    {
      dataField: "type",
      caption: "Type",
      dataSource: [
        { key: 1, value: "Particulier" },
        { key: 2, value: "Entreprise" },
      ],
      displayExpr: "value",
      valueExpr: "key",
    },
    { dataField: "adresses", caption: "Adresse" },
    { dataField: "phone", caption: "Contact 1" },
    { dataField: "otherPhone", caption: "Contact 2" },
    { dataField: "email", caption: "Mail 1" },
    { dataField: "otherEmail", caption: "Mail 2" },
    {
      dataField: "creator.name",
      caption: "Créer par",
    },
    { dataField: "creationDate", caption: "Créer le", dataType: "date" },
    {
      dataField: "modifier.name",
      caption: "Modifier par",
    },
    {
      dataField: "lastUpdate",
      caption: "Date dernière modification",
      dataType: "date",
    },
  ];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: true,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier le client",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/customer/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    async function getCustomers() {
      const { data: customers } = await customerService.getCustomers();
      setCustomers(customers);
    }
    getCustomers();
  }, [props]);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint = "Ajouter un nouveau client";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/customer/new");
    e.toolbarOptions.items.unshift();
  };

  const handleOnRowRemoved = async ({ key }) => {
    try {
      await customerService.deleteCustomer(key);
      toast.success("Le client a été supprimé");
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error(ex.response.data);
    }
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={customers}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
        onRowRemoved={handleOnRowRemoved}
      />
    </div>
  );
}
