import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import errors from "./utils/errors";
import unitService from "../services/unitService";
import detailSectionService from "../services/detailSectionService";
import articleService from "../services/articleService";
import { toast } from "react-toastify";

class ArticleForm extends Form {
  state = {
    data: { name: "", unitId: "", detailSectionId: "", price: "" },
    errors: {},
    units: [],
    detailSections: [],
  };

  schema = {
    id: Joi.any().optional(),
    name: Joi.string().required().label("Libelle").error(errors),
    unitId: Joi.string().required().label("Unité").error(errors),
    detailSectionId: Joi.string().required().label("Catégorie").error(errors),
    price: Joi.number().min(0).required().label("Prix").error(errors),
    lastUpdate: Joi.any().optional(),
  };

  async populateArticle() {
    const articleId = this.props.match.params.id;
    try {
      if (articleId === "new") {
        const artilce = {
          name: "",
          unitId: "",
          detailSectionId: "",
          price: "",
        };
        this.setState({ data: artilce });
        return;
      }
      const { data } = await articleService.getArticle(parseInt(articleId));
      data.price = data.price.toString();
      data.unitId = data.unitId.toString();
      data.detailSectionId = data.detailSectionId.toString();
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    const { data: units } = await unitService.getUnits();
    const { data: detailSections } =
      await detailSectionService.getDetailSections();
    this.setState({ units, detailSections });
    await this.populateArticle();
  }

  doSubmit = async () => {
    try {
      await articleService.saveArticle(this.convertArticleProperties());
      this.props.history.push("/list-articles");
      toast.success("L'article a été enregistrée");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        if (ex.response.data.errors && ex.response.data.errors.UnitId) {
          errors.unitId = "Veuillez chosir une unité valide dans la liste";
        }
        if (
          ex.response.data.errors &&
          ex.response.data.errors.detailSectionId
        ) {
          errors.detailSectionId =
            "Veuillez chosir une catégorie valide dans la liste";
        }
        this.setState({ errors });
      }
    }
  };

  convertArticleProperties() {
    const article = { ...this.state.data };
    article.unitId = parseInt(article.unitId) || 0;
    article.detailSectionId = parseInt(article.detailSectionId) || 0;
    return article;
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="w-50">
          {this.renderDataList(
            "detailSectionId",
            "Catégorie",
            this.state.detailSections,
            "detailSections"
          )}
          {this.renderInput("name", "Article")}
          {this.renderDataList("unitId", "Unité", this.state.units, "units")}
          {this.renderInput("price", "Prix", "number")}
          {this.renderButton("Enregistrer")}
        </form>
      </div>
    );
  }
}

export default ArticleForm;
