import http from "./httpService";

const endPoint = "detailSections";

function detailSectionUrl(id) {
  return `${endPoint}/${id}`;
}

function saveDetailSection(detailSection) {
  if (detailSection.id === undefined || detailSection.id === 0)
    return http.post(endPoint, detailSection);
  return http.put(detailSectionUrl(detailSection.id), detailSection);
}

function getDetailSections() {
  return http.get(endPoint);
}

function getDetailSection(detailsectionId) {
  return http.get(detailSectionUrl(`GetDetailSection/${detailsectionId}`));
}

export default {
  getDetailSections,
  saveDetailSection,
  getDetailSection,
};
