import http from "./httpService";

const endPoint = "enterprises";

function getEnterprises() {
  return http.get(enterpriseUrl("GetEnterprises"));
}

function getRateTVA() {
  return http.get(enterpriseUrl("GetRateTVA"));
}

function enterpriseUrl(id) {
  return `${endPoint}/${id}`;
}

function saveEnterprise(enterprise) {
  if (enterprise.id === undefined || enterprise.id === 0)
    return http.post(endPoint, enterprise);
  return http.put(enterpriseUrl(enterprise.id), enterprise);
}

function getEnterprise(enterpriseId) {
  return http.get(enterpriseUrl(`GetEnterprise/${enterpriseId}`));
}

export default {
  getEnterprises,
  getEnterprise,
  getRateTVA,
  saveEnterprise,
};
