import React from "react";

function CheckBox({ label, value, ...rest }) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value={value}
        id={value}
        {...rest}
      ></input>
      <label className="form-check-label" htmlFor={value}>
        {label}
      </label>
    </div>
  );
}

export default CheckBox;
