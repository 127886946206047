import React from "react";
import Input from "./common/Input";
import DataList from "./common/DataList";

const InvoiceQuoteGeneralInfo = (props) => {
  const {
    NumberLibelle,
    createdDate,
    data,
    customers,
    onHandleChangeDataList,
    onhandleClick,
    onHandleChange,
  } = props;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          <p>
            {NumberLibelle}
            {data.number}
          </p>
          <Input
            onChange={onHandleChange}
            value={data[createdDate]}
            id={createdDate}
            name={createdDate}
            placeholder="En date du"
            type="date"
          />
        </div>
        <div className="col-md-4 offset-md-4">
          <div hidden={!data.infoCustomer.isVisibleCustomer}>
            <DataList
              id="customerId"
              list="customers"
              name="customerId"
              data={customers}
              placeholder="Choisir un client."
              value={data["customerId"]}
              onChange={(e) => onHandleChangeDataList(e, customers)}
            />
          </div>
          <div
            className="info-customer"
            hidden={data.infoCustomer.isVisibleCustomer}
          >
            <strong>{data.infoCustomer.name}</strong>
            <br />
            {data.infoCustomer.adresses}

            <span className="edit">
              <i className="dx-icon-edit" onClick={() => onhandleClick()}></i>
            </span>
          </div>
          <Input
            onChange={onHandleChange}
            value={data["projectName"]}
            id="projectName"
            name="projectName"
            placeholder="Nom du projet"
            type="text"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default InvoiceQuoteGeneralInfo;
