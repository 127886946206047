import React from "react";
const Select = ({ name, label, data, error, ...rest }) => {
  return (
    <div className="form-group">
      {label ? <label htmlFor={name}>{label}</label> : null}
      <select id={name} name={name} {...rest} className="form-control">
        <option></option>
        {data.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
