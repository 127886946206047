function init() {}

function log() {
  console.log("logginng error");
}

export default {
  init,
  log,
};
