import React, { useEffect, useState } from "react";
import roleService from "../services/roleService";
import Grid from "./common/Grid";

function Roles(props) {
  const [roles, setRoles] = useState([]);
  const columns = [
    { dataField: "name", caption: "Libelle" },
    { dataField: "description", caption: "Description" },
  ];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: false,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier le rôle",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/role/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    (async function () {
      const { data } = await roleService.getRoles();
      setRoles(data);
    })();
  }, [props]);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint = "Ajouter une nouvelle catégorie";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/role/new");
    e.toolbarOptions.items.unshift();
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={roles}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
      />
    </div>
  );
}

export default Roles;
