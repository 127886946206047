import React, { useEffect, useState } from "react";
import activityService from "../services/activityService";
import Grid from "./common/Grid";

function Activities(props) {
  const [activities, setActivities] = useState([]);
  const columns = [{ dataField: "name", caption: "Libelle" }];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: false,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier la catégorie",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/activity/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    (async function () {
      const { data } = await activityService.getActivities();
      setActivities(data);
    })();
  }, [props]);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint = "Ajouter une nouvelle activité";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/activity/new");
    e.toolbarOptions.items.unshift();
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={activities}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
      />
    </div>
  );
}

export default Activities;
