import convertToFloat from "./convertToFloat";
import convertToInt from "./convertToInt";
import downPaymentsSum from "./downPaymentsSum";
import downPaymentsFilter from "./downPaymentsFilter";
import convertToPersent from "./convertToPersent";

export default function getNumber(details, isSection, lastItem) {
  const value = {
    number: 0,
    numberSection: 0,
  };
  if (details.length === 0) {
    value.number = 1;
    value.numberSection = 0;
  } else if (isSection && lastItem && lastItem.isSection) {
    value.number = lastItem.number + 1;
    value.numberSection = 0;
  } else if (isSection && lastItem && !lastItem.isSection) {
    const valueSplit = parseInt(lastItem.number.toString().split(".")[0]);
    value.number = valueSplit + 1;
    value.numberSection = 0;
  } else if (!isSection && lastItem && !lastItem.isSection) {
    const valueSplit = lastItem.number.toString().split(".");
    if (valueSplit.length === 1) {
      value.number = parseInt(valueSplit[0]) + 1;
      value.numberSection = 0;
    } else {
      value.number = addZeroPointOneToNumber(lastItem.number);
      value.numberSection = parseInt(valueSplit[0]);
    }
  } else if (!isSection && lastItem && lastItem.isSection) {
    value.number = addZeroPointOneToNumber(lastItem.number);
    value.numberSection = lastItem.number;
  }
  return value;
}

export function getDetailArticles(data, detail) {
  return data.details.filter((d) => d.numberSection === detail.number);
}

export function getValideDetailArticles(data, detail) {
  return data.details.filter(
    (d) => d.numberSection === detail.number && d.articleId
  );
}

export function splitNumber(data, i) {
  data.details[i].number =
    data.details[i].number.toString().split(".").length === 1
      ? --data.details[i].number
      : parseFloat((data.details[i].number - 1).toFixed(1));
  if (data.details[i].numberSection !== 0) data.details[i].numberSection--;
}

export function updateSection(detail, data) {
  if (!detail.isSection && detail.numberSection !== 0) {
    const section = data.details.find(
      (d) => d.isSection && d.number === detail.numberSection
    );
    const indexSection = data.details.indexOf(section);
    recalculSectionTotal(data, section, indexSection);
    return;
  }
  calculTotalTTC(data);
}

export function recalculSectionTotal(data, detail, index) {
  const articles = getValideDetailArticles(data, detail);
  data.details[index]["total"] =
    articles.length > 0
      ? articles.reduce(
          (a, currentValue) => a + convertToFloat(currentValue.total),
          0
        )
      : 0;
  calculTotalTTC(data);
}
export function calculTotalTTC(data) {
  data.totalInvoice =
    data.details.reduce(
      (a, currentValue) =>
        parseInt(currentValue.numberSection) === 0
          ? a + convertToFloat(currentValue.total)
          : a,
      0
    ) - downPaymentsSum(downPaymentsFilter(data.downPayments));

  data.discount = getDiscountValue(data, data.rateDiscount);

  data.totalHT = convertToInt(data.totalInvoice) - convertToInt(data.discount);
  data.tva = parseFloat(data.totalHT) * convertToPersent(data.rateTVA);
  data.totalTTC = data.tva + data.totalHT;
}
export function calculDownPaymentTotalTTC(data) {
  data.tva = parseFloat(data.totalHT) * convertToPersent(data.rateTVA);
  data.totalTTC = data.tva + data.totalHT;
  data.resteTTC = data.reste + data.reste * convertToPersent(data.rateTVA);
}

export function convertToNumber(value) {
  return value !== "" ? parseFloat(value) : 0;
}

export function addZeroPointOneToNumber(number) {
  return parseFloat((parseFloat(number) + 0.1).toFixed(1));
}

export function getSousSectionByNumberSection(data, detail) {
  return data.details.filter((d) => d.numberSection === detail.numberSection);
}

export function getSousSectionLastIndex(data, detail) {
  const sousSections = getSousSectionByNumberSection(data, detail);

  return data.details.indexOf(sousSections[sousSections.length - 1]);
}

export function getDiscountValue(data, value) {
  if (data["modeDiscount"] === "%" && value !== "") {
    data["discount"] = convertToPersent(value) * data.totalInvoice;
  } else if (data["modeDiscount"] === "FCFA" && value !== "")
    data["discount"] = parseFloat(value);
  else if (
    (data["modeDiscount"] === "FCFA" || data["modeDiscount"] === "%") &&
    value === ""
  )
    data["discount"] = 0;

  return data["discount"];
}
