import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import errors from "./utils/errors";
import Details from "./Details";
import articleService from "../services/articleService";
import unitService from "../services/unitService";
import http from "../services/httpService";
import getNumber, {
  getDetailArticles,
  updateSection,
  convertToNumber,
  calculTotalTTC,
  calculDownPaymentTotalTTC,
} from "./utils/number";
import {
  deleteAllSectionWithArticles,
  deleteSection,
  deleteArticle,
  deleteItem,
} from "./utils/removeDetail";
import Modal from "./common/Modal";
import invoiceService from "../services/invoiceService";
import detailSectionService from "../services/detailSectionService";
import customerService from "../services/customerService";
import { actionsInvoiceState } from "./utils/actions";
import { menuItems, menuItemsWithoutSection } from "./utils/menuItems";
import { insertDown, insertUp } from "./utils/insertDetail";
import populateCustomerInfo from "./utils/populateCustomerInfo";
import { formattingISODate } from "./utils/formattingISODate";
import populateUnitId from "./utils/populateUnit";
import { toast } from "react-toastify";
import { printInvoiceInNewWindow } from "./utils/printInNewWindow";
import { enumState, enumTypeInvoice } from "./utils/state";
import DownPayment from "./DownPayment";
import downPaymentsFilter from "./utils/downPaymentsFilter";
import downPaymentsSum from "./utils/downPaymentsSum";
import InvoiceQuoteGeneralInfo from "./InvoiceQuoteGeneralInfo";
import ActionsInvoiceQuoteForm from "./common/ActionsInvoiceQuoteForm";
import InvoiceAreaTotal from "./InvoiceAreaTotal";
import AcceptedPaymentFormating from "./acceptedPaymentFormating";
import Input from "./common/Input";

class InvoiceForm extends Form {
  state = {
    data: {
      number: "",
      customerId: "",
      quoteId: "",
      infoCustomer: {
        isVisibleCustomer: true,
        name: "",
        adresses: "",
      },
      objet: "",
      reste: "",
      resteTTC: "",
      discount: "",
      rateDiscount: "",
      totalHT: "",
      totalTTC: "",
      tva: "",
      rateTVA: "",
      projectName: "",
      notes: "",
      conditions: "",
      invoiceDate: "",
      isCanceled: "",
      modeDiscount: "FCFA",
      totalInvoice: "",
      type: "",
      reportNumber: "",
      details: [],
    },
    errors: {},
    articles: [],
    units: [],
    detailSections: [],
    customers: [],

    modalOptions: {
      isPopupVisible: false,
      width: 500,
      height: 222,
      message: "",
      toolbarItems: [],
    },
  };

  schema = {
    id: Joi.any().optional(),
    number: Joi.string().required().label("Numéro devis").error(errors),
    customerId: Joi.any().required().label("Client").error(errors),
    projectName: Joi.string().required().label("Nom du project").error(errors),
    invoiceDate: Joi.string().required().label("Date création").error(errors),
    notes: Joi.any().optional().label("Notes").error(errors),
    conditions: Joi.any().optional().label("Conditions").error(errors),
    modeDiscount: Joi.any().optional().label("mode discount").error(errors),
    discount: Joi.any().optional().label("Discount").error(errors),
    rateDiscount: Joi.any().optional(),
    details: Joi.any().optional(),
    isCanceled: Joi.any().optional(),
    totalHT: Joi.any().optional(),
    totalTTC: Joi.any().optional(),
    tva: Joi.any().optional(),
    rateTVA: Joi.any().optional(),
    reste: Joi.any().optional(),
    resteTTC: Joi.any().optional(),
    objet: Joi.any().optional(),
    quoteId: Joi.any().optional(),
    infoCustomer: Joi.any().optional(),
    quoteNumber: Joi.any().optional(),
    totalInvoice: Joi.any().optional(),
    state: Joi.any().optional(),
    typeInvoice: Joi.any().optional(),
    isDeduct: Joi.any().optional(),
    downPayments: Joi.any().optional(),
    subTotal: Joi.any().optional(),
    type: Joi.any().optional(),
    reportNumber: Joi.any().optional(),
  };

  async populateInvoice() {
    try {
      const invoiceId = this.props.match.params.id;
      if (invoiceId === "new") {
        const data = {
          number: "",
          customerId: "",
          quoteId: "",
          infoCustomer: {
            isVisibleCustomer: true,
            name: "",
            adresses: "",
          },
          objet: "",
          reste: "",
          resteTTC: "",
          discount: "",
          rateDiscount: "",
          totalHT: "",
          totalTTC: "",
          tva: "",
          rateTVA: "",
          projectName: "",
          notes: "",
          conditions: "",
          invoiceDate: "",
          isCanceled: "",
          modeDiscount: "FCFA",
          totalInvoice: "",
          type: "",
          reportNumber: "",
          details: [],
        };
        const { data: number } = await invoiceService.getNewInvoiceNumber();
        data.number = number;
        this.setState({ data });
        return;
      }
      const { data } = await invoiceService.getInvoice(parseInt(invoiceId));
      data.invoiceDate = formattingISODate(data.invoiceDate);
      const infoCustomer = {
        isVisibleCustomer: false,
        name: "",
        adresses: "",
      };
      data.infoCustomer = populateCustomerInfo(
        data.customerId,
        infoCustomer,
        this.state.customers
      );
      populateUnitId(data.details, this.state.articles);
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  populateArticlesUnitsCustomerDetailSections() {
    let sources = [
      articleService.getArticles(),
      unitService.getUnits(),
      detailSectionService.getDetailSections(),
      customerService.getCustomers(),
    ];
    http
      .all(sources)
      .then(
        http.spread((...responses) => {
          this.setState({
            articles: responses[0].data,
            units: responses[1].data,
            detailSections: responses[2].data,
            customers: responses[3].data,
          });
        })
      )
      .catch((ex) => {
        if (ex.response && ex.response.status === 404)
          this.props.history.push("/not-found");
      });
  }

  handleChangeDetail = ({ currentTarget: input }, detail) => {
    const data = { ...this.state.data };
    data.details = [...this.state.data.details];
    const index = data.details.indexOf(detail);
    data.details[index] = { ...detail };
    data.details[index][input.name] = input.value;

    if (input.name === "quantity" && data.details[index].price !== "") {
      data.details[index]["total"] = input.value * data.details[index].price;
      updateSection(detail, data);
    }

    if (input.name === "price" && data.details[index].quantity !== "") {
      data.details[index]["total"] = input.value * data.details[index].quantity;
      updateSection(detail, data);
    }

    this.setState({ data });
  };

  handleChangeDataListDetail = (
    { currentTarget: input },
    options = [],
    detail
  ) => {
    const item = options.find((d) => d.name.trim() === input.value.trim());
    const value = item !== undefined ? item.id : input.value;
    const data = { ...this.state.data };
    data.details = [...this.state.data.details];
    const index = data.details.indexOf(detail);
    data.details[index] = { ...detail };
    data.details[index][input.name] = value.toString();

    if (input.name === "articleId" && item) {
      const unitId = item.unit.id;
      data.details[index]["price"] = item.price;
      data.details[index]["total"] = data.details[index].quantity * item.price;
      data.details[index]["unitId"] = unitId;
      updateSection(detail, data);
    }

    if (input.name === "detailSectionId") {
      if (!item) {
        data.details[index]["libelleDetailSectionId"] = input.value.trim();
        // data.details[index][input.name] = "";
      }
    }
    this.setState({ data });
  };

  addDetail = (isSection) => {
    const data = { ...this.state.data };
    let lastItem = data.details[data.details.length - 1];
    const value = getNumber(data.details, isSection, lastItem);
    data.details.push({
      isSection,
      number: value.number,
      numberSection: value.numberSection,
      detailSectionId: "",
      articleId: "",
      unitId: "",
      quantity: "1",
      price: "",
      total: "",
    });
    this.setState({ data });
  };

  renderModal = (message, toolbarItems, width, height) => {
    const modalOptions = { ...this.state.modalOptions };
    modalOptions.isPopupVisible = true;
    modalOptions.width = width;
    modalOptions.height = height;
    modalOptions.message = message;
    modalOptions.toolbarItems = toolbarItems;
    this.setState({ modalOptions });
  };

  togglePopup = () => {
    const modalOptions = { ...this.state.modalOptions };
    modalOptions.isPopupVisible = !this.state.modalOptions.isPopupVisible;

    this.setState({ modalOptions });
  };

  removeDetail = (detail, data, index) => {
    data.details = data.details.filter((d) => d.number !== detail.number);
    if (detail.isSection) {
      var artilces = getDetailArticles(data, detail);
      if (artilces.length > 0)
        this.renderModal(
          "Comment souhaitez-vous supprimer cette section?",
          [
            {
              options: {
                text: "Supprimer toute la section",
                stylingMode: "contained",
                type: "danger",
                onClick: () => {
                  deleteAllSectionWithArticles(data, detail);
                  this.ClosePopupChangeState(data);
                },
              },
            },
            {
              options: {
                text: "Supprimer et conserver le contenu",
                stylingMode: "contained",
                type: "default",
                onClick: () => {
                  deleteSection(data, detail, index);
                  this.ClosePopupChangeState(data);
                },
              },
            },
          ],
          700,
          222
        );
      else
        this.renderModal(
          "Confirmer la suppression?",
          [
            {
              options: {
                text: "Oui, supprimer",
                stylingMode: "contained",
                type: "danger",
                onClick: () => {
                  deleteItem(index, data);
                  this.ClosePopupChangeState(data);
                },
              },
            },
          ],
          500,
          222
        );
    } else
      this.renderModal(
        "Confirmer la suppression?",
        [
          {
            options: {
              text: "Oui, supprimer",
              stylingMode: "contained",
              type: "danger",
              onClick: () => {
                deleteArticle(detail, data, index);
                this.ClosePopupChangeState(data);
              },
            },
          },
        ],
        500,
        222
      );
  };

  clickAction = ({ itemData }, detail) => {
    const data = { ...this.state.data };
    data.details = [...this.state.data.details];
    const index = data.details.indexOf(detail);
    data.details[index] = { ...detail };
    if (itemData.id !== undefined) {
      const menuItemss =
        !detail.isSection && detail.numberSection !== 0
          ? menuItemsWithoutSection
          : menuItems;
      if (itemData.id === menuItemss[0].items[2].id) {
        this.removeDetail(detail, data, index);
        return;
      } else if (itemData.id === menuItemss[0].items[0].items[0].id)
        insertUp(false, index, detail, data);
      else if (itemData.id === menuItemss[0].items[1].items[0].id)
        insertDown(false, index, detail, data);
      else if (itemData.id === menuItemss[0].items[0].items[1].id)
        insertUp(true, index, detail, data);
      else if (itemData.id === menuItemss[0].items[1].items[1].id)
        insertDown(true, index, detail, data);
    }

    this.setState({ data });
  };

  ClosePopupChangeState(data) {
    this.togglePopup();
    this.setState({ data });
  }

  async componentDidMount() {
    this.populateArticlesUnitsCustomerDetailSections();
    await this.populateInvoice();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.populateInvoice();
    }
  }

  doSubmit = async () => {
    try {
      const { data: invoice } = await invoiceService.saveInvoice(
        this.convertInvoiceProperties()
      );
      toast.success("La facture a été enregistrée");
      if (this.props.match.params.id === "new") {
        // this.props.history.push(`/invoice/${invoice.id}`);
        window.location = `/invoice/${invoice.id}`;
        return;
      }
      // this.props.history.push(`/invoice/${invoice.id}`);
      window.location = `/invoice/${invoice.id}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) toast.error(ex.response);
    }
  };

  convertInvoiceProperties = () => {
    const invoice = { ...this.state.data };
    invoice.customerId = convertToNumber(invoice.customerId);
    invoice.quoteId =
      invoice.quoteId === "" ? null : convertToNumber(invoice.quoteId);
    invoice.discount = convertToNumber(invoice.discount);
    invoice.reste = convertToNumber(invoice.reste);
    invoice.totalHT = convertToNumber(invoice.totalHT);
    invoice.totalTTC = convertToNumber(invoice.totalTTC);
    invoice.rateTVA = convertToNumber(invoice.rateTVA);
    invoice.tva = convertToNumber(invoice.tva);
    invoice.isCanceled = false;
    invoice.resteTTC = convertToNumber(invoice.resteTTC);
    invoice.type = convertToNumber(invoice.type);
    invoice.rateDiscount = convertToNumber(invoice.rateDiscount);
    for (let i = 0; i < invoice.details.length; i++) {
      invoice.details[i] = { ...invoice.details[i] };
      invoice.details[i].number = invoice.details[i].number.toString();
      invoice.details[i].numberSection =
        invoice.details[i].numberSection.toString();
      invoice.details[i].articleId = convertToNumber(
        invoice.details[i].articleId
      );
      invoice.details[i].unitId = convertToNumber(invoice.details[i].unitId);
      // if (isNaN(invoice.details[i].detailSectionId)) {
      if (invoice.details[i].libelleDetailSectionId) {
        invoice.details[i].detailSectionId = 0;
        invoice.details[i].detailSection = {
          name: invoice.details[i].libelleDetailSectionId,
        };
      } else
        invoice.details[i].detailSectionId = convertToNumber(
          invoice.details[i].detailSectionId
        );

      invoice.details[i].total = convertToNumber(invoice.details[i].total);
      invoice.details[i].price = convertToNumber(invoice.details[i].price);
      invoice.details[i].quantity = convertToNumber(
        invoice.details[i].quantity
      );
    }

    return invoice;
  };

  handleClick = () => {
    const data = { ...this.state.data };
    data.infoCustomer.isVisibleCustomer = true;
    data.customerId = "";

    this.setState({ data });
  };

  handleChangeInvoiceState = async (state) => {
    const invoiceId = this.props.match.params.id;
    const data = { ...this.state.data };
    await invoiceService.changeInvoiceState(invoiceId, state);
    data.state = state;
    this.setState({ data });
    toast.success("Le statut du devis a changé");
  };

  onActionClick = ({ itemData: action }) => {
    switch (action) {
      case actionsInvoiceState[0]:
        this.handleChangeInvoiceState(parseInt(enumState.Draft));
        break;
      case actionsInvoiceState[1]:
        this.handleChangeInvoiceState(parseInt(enumState.final));
        break;
      case actionsInvoiceState[2]:
        this.handleChangeInvoiceState(parseInt(enumState.Sended));
        break;
      case actionsInvoiceState[3]:
        this.handleChangeInvoiceState(parseInt(enumState.Paid));
        break;
      default:
        break;
    }
  };
  onDeduct = (index) => {
    const data = { ...this.state.data };
    data.downPayments[index] = { ...data.downPayments[index] };
    data.downPayments[index].isDeduct = !data.downPayments[index].isDeduct;
    data.totalHT =
      parseFloat(data.subTotal) -
      downPaymentsSum(downPaymentsFilter(data.downPayments)) -
      parseFloat(data.discount);
    this.setState({ data });
  };

  handleChangeRateTVA = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data.rateTVA = parseFloat(input.value);
    if (data.type === 2) calculDownPaymentTotalTTC(data);
    else calculTotalTTC(data);
    this.setState({ data });
  };

  render() {
    const { modalOptions, customers, detailSections, articles, units, data } =
      this.state;
    const invoiceId = this.props.match.params.id;
    return (
      <div className="Quote-form">
        <Modal
          isPopupVisible={modalOptions.isPopupVisible}
          togglePopup={this.togglePopup}
          width={modalOptions.width}
          height={modalOptions.height}
          message={modalOptions.message}
          toolbarItems={modalOptions.toolbarItems}
        />
        <InvoiceQuoteGeneralInfo
          NumberLibelle="Facture N°"
          createdDate="invoiceDate"
          data={data}
          customers={customers}
          onHandleChangeDataList={this.handleChangeDataList}
          onhandleClick={this.handleClick}
          onHandleChange={this.handleChange}
        />

        {data.type === enumTypeInvoice.Final ? (
          <div className="w-25">
            <Input
              onChange={this.handleChange}
              value={data["reportNumber"]}
              id={"reportNumber"}
              name={"reportNumber"}
              placeholder="N° Rapport"
              type="text"
            />
          </div>
        ) : null}
        {this.renderInput("objet", "Objet")}
        <Details
          details={this.state.data.details}
          detailSections={detailSections}
          articles={articles}
          units={units}
          onHandleChangeDataListDetail={this.handleChangeDataListDetail}
          onHandleChangeDetail={this.handleChangeDetail}
          onClickAction={this.clickAction}
          onAddDetail={this.addDetail}
        />

        {data.downPayments && data.downPayments.length > 0 ? (
          <DownPayment
            downPayments={data.downPayments}
            quoteNumber={data.quoteNumber}
            onDeduct={this.onDeduct}
          />
        ) : null}
        <InvoiceAreaTotal
          onHandleChange={this.handleChange}
          data={data}
          downPaymentsFilter={downPaymentsFilter}
          onHandleChangeRateTVA={this.handleChangeRateTVA}
        />
        <AcceptedPaymentFormating />

        {this.renderTextarea("notes", "Notes")}
        <ActionsInvoiceQuoteForm
          quoteId={invoiceId}
          onValidate={this.validate}
          onHandleSubmit={this.handleSubmit}
          OnPrintInNewWindow={printInvoiceInNewWindow}
          actions={actionsInvoiceState}
          onActionClick={this.onActionClick}
          onHandleCanceled={this.handleCanceled}
        />
      </div>
    );
  }
}

export default InvoiceForm;
