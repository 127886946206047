import http from "./httpService";

const endPoint = "invoices";

function invoiceUrl(id) {
  return `${endPoint}/${id}`;
}

function saveInvoice(invoice) {
  if (invoice.id === undefined || invoice.id === 0)
    return http.post(endPoint, invoice);
  return http.put(invoiceUrl(invoice.id), invoice);
}

function getInvoices() {
  return http.get(invoiceUrl("GetInvoices"));
}
function getUnPaidInvoices() {
  return http.get(invoiceUrl("GetUnPaidInvoices"));
}
function getPaidInvoices() {
  return http.get(invoiceUrl("GetPaidInvoices"));
}

function getInvoice(invoiceId) {
  return http.get(invoiceUrl(`GetInvoice/${invoiceId}`));
}

function getNewInvoiceNumber() {
  return http.get(invoiceUrl("GetNewInvoiceNumber"));
}

function changeInvoiceState(invoiceId, state) {
  return http.patch(invoiceUrl(`ChangeInvoiceState/${invoiceId}/${state}`));
}

function deleteInvoice(invoiceId) {
  return http.delete(invoiceUrl(invoiceId));
}

function createInvoiceByQuoteId(quoteId) {
  return http.post(invoiceUrl(`CreateInvoiceByQuoteId/${quoteId}`));
}

function createDownPaymentByQuoteId(quoteId, down) {
  return http.post(invoiceUrl(`CreateDownPaymentByQuoteId/${quoteId}/`), down);
}
// function createDownPaymentByQuoteId(quoteId, montant) {
//   return http.post(
//     invoiceUrl(`CreateDownPaymentByQuoteId/${quoteId}/${montant}`)
//   );
// }

export default {
  saveInvoice,
  getInvoices,
  getInvoice,
  getUnPaidInvoices,
  getPaidInvoices,
  getNewInvoiceNumber,
  changeInvoiceState,
  createInvoiceByQuoteId,
  createDownPaymentByQuoteId,
  deleteInvoice,
};
