import React, { useEffect, useState } from "react";
import invoiceService from "../services/invoiceService";
import Grid from "./common/Grid";
import { liststateInvoice } from "./utils/state";
import { stateInvoiceRender } from "./utils/stateCell";
import formatCfa from "./utils/formatCfa";
import quoteService from "../services/quoteService";

export default function Dashbords(props) {
  const [unPaidInvoices, setUnPaidInvoices] = useState([]);
  const [quotes, setQuotes] = useState([]);

  const editing = {
    mode: "row",
    allowAdding: false,
  };

  const columns = [
    { dataField: "number", caption: "Numéro" },
    {
      dataField: "type",
      caption: "Type",
      dataSource: [
        { key: 1, value: "Finale" },
        { key: 2, value: "Acompte" },
      ],
      displayExpr: "value",
      valueExpr: "key",
    },
    {
      dataField: "state",
      caption: "Statut",
      dataSource: liststateInvoice,
      displayExpr: "value",
      valueExpr: "key",
      cellRender: stateInvoiceRender,
    },
    {
      dataField: "customer.name",
      caption: "Client",
      groupIndex: 0,
    },
    { dataField: "quote.name", caption: "N° devis" },
    { dataField: "projectName", caption: "Projet" },
    { dataField: "invoiceDate", caption: "En date du", dataType: "date" },
    { dataField: "totalHT", caption: "Total", format: "#,##0 CFA" },
  ];

  useEffect(() => {
    async function getInvoices() {
      const { data: unpaidInvoices } = await invoiceService.getUnPaidInvoices();
      const { data: allQuotes } =
        await quoteService.getAcceptedQuotesWithoutInvoices();
      setUnPaidInvoices(unpaidInvoices);
      setQuotes(allQuotes);
    }

    getInvoices();
  }, [props]);

  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <div className="card text-center text-white dashbord-primary">
            <div className="card-body">
              <h5 className="card-title">Total impayé</h5>
              <p className="card-text">
                {formatCfa(
                  unPaidInvoices.reduce(
                    (a, currentValue) => a + currentValue.totalHT,
                    0
                  )
                )}
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card text-center text-white dashbord-secondary">
            <div className="card-body">
              <h5 className="card-title">
                Devis validés en attente de facturation
              </h5>
              <p className="card-text">
                Vous avez {quotes.length} devis en cours pour
                {formatCfa(
                  quotes.reduce(
                    (a, currentValue) => a + currentValue.totalHT,
                    0
                  )
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Grid
        columns={columns}
        dataSource={unPaidInvoices}
        isGoupPanelVisible={true}
        autoExpandAll={true}
        editing={editing}
        rowAlternationEnabled={false}
        summary={{ type: "sum", column: "totalHT", format: "#,##0 CFA" }}
      />
    </div>
  );
}
