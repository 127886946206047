import React from "react";
import Discount from "./common/Discount";
import DownPaymentTotal from "./DownPaymentTotal";
import formatCfa from "./utils/formatCfa";
import { hiddenDiscount } from "./utils/hidden";
import { enumTypeInvoice } from "./utils/state";

const InvoiceAreaTotal = (props) => {
  const { onHandleChange, data, onHandleChangeRateTVA, downPaymentsFilter } =
    props;

  return (
    <React.Fragment>
      <div className="row mt-3">
        <div className="col-7"></div>
        <div className="col-5">
          {data.type === enumTypeInvoice.Final ? (
            <Discount onHandleChange={onHandleChange} data={data} />
          ) : null}
          <div className="cards">
            <div className="row subtotal">
              <div className="col-6 left">
                <strong>Sous total</strong>
              </div>
              <div className="col-6 rigth">
                <strong>{formatCfa(data.subTotal)}</strong>
              </div>
            </div>
            {data.downPayments && data.downPayments.length > 0 ? (
              <React.Fragment>
                <div className="row subtotal" hidden={hiddenDiscount(data)}>
                  <div className="col-6 left">
                    <strong>Total remise</strong>
                  </div>
                  <div className="col-6 rigth">
                    <strong>{formatCfa(data.discount)}</strong>
                  </div>
                </div>
                <DownPaymentTotal
                  downPayments={downPaymentsFilter(data.downPayments)}
                />
              </React.Fragment>
            ) : null}
            <div className="row subtotal">
              <div className="col-6 left">
                <strong>Total HT</strong>
              </div>
              <div className="col-6 rigth">
                <strong>{formatCfa(data.totalHT)}</strong>
              </div>
            </div>
            <div className="row subtotal">
              <div className="col-6 left ">
                <div className="row">
                  <div className="col-6 tva">
                    <strong>TVA</strong>
                  </div>
                  <div className="col-6 form-group tva">
                    <input
                      type="number"
                      className="form-control"
                      min={0}
                      name="rateTVA"
                      value={data["rateTVA"]}
                      onChange={onHandleChangeRateTVA}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 rigth">
                <strong>{formatCfa(data.tva)}</strong>
              </div>
            </div>
            <div className="row subtotal">
              <div className="col-6 left">
                <strong>Total TTC</strong>
              </div>
              <div className="col-6 rigth">
                <strong>{formatCfa(data.totalTTC)}</strong>
              </div>
            </div>
            <div className="row subtotal">
              <div className="col-6 left">
                <strong>Reste à facturer HT</strong>
              </div>
              <div className="col-6 rigth">
                <strong>{formatCfa(data.reste)}</strong>
              </div>
            </div>
            <div className="row subtotal">
              <div className="col-6 left">
                <strong>Reste à facturer TTC</strong>
              </div>
              <div className="col-6 rigth">
                <strong>{formatCfa(data.resteTTC)}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InvoiceAreaTotal;
