import React, { Component } from "react";
import Input from "./common/Input";
import DataList from "./common/DataList";
import total from "./utils/total";
import { Menu } from "devextreme-react/menu";
import { menuItems, menuItemsWithoutSection } from "./utils/menuItems";

class DetailsQuote extends Component {
  render() {
    const { articles, units, detail: data, onClickAction } = this.props;
    return (
      <div className="row  detail">
        <div className="col-1 padding-top">{data.number}</div>
        <div className="col-4">
          <DataList
            autoFocus={true}
            id="articleId"
            list="article"
            name="articleId"
            data={articles}
            placeholder="Rechercher un article."
            value={data["articleId"]}
            onChange={(e) => this.props.onChangeDataList(e, articles, data)}
          />
        </div>
        <div className="col-1">
          <DataList
            id="unitId"
            list="units"
            name="unitId"
            data={units}
            value={data["unitId"]}
            onChange={(e) => this.props.onChangeDataList(e, units, data)}
          />
        </div>
        <div className="col-1">
          <Input
            onChange={(e) => this.props.onChange(e, data)}
            value={data["quantity"]}
            id="quantity"
            name="quantity"
            placeholder="Quantité"
            type="number"
          />
        </div>
        <div className="col-2">
          <Input
            onChange={(e) => this.props.onChange(e, data)}
            value={data["price"]}
            id="price"
            name="price"
            placeholder="0 FCFA"
            type="number"
          />
        </div>
        <div className="col-2 total-sous-section padding-top">
          {total(data.total)}
        </div>
        {/* <div className="col-2 total-sous-section">{formatCfa(data.total)}</div> */}
        <div className="col-1 trash">
          <Menu
            items={
              data.numberSection !== 0 ? menuItemsWithoutSection : menuItems
            }
            displayExpr="name"
            onItemClick={(e) => onClickAction(e, data)}
          />
        </div>
      </div>
    );
  }
}

export default DetailsQuote;
