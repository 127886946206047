import { badge } from "./badge";
import { enumState } from "./state";

export function stateRender(cellData) {
  switch (cellData.value.toString()) {
    case enumState.Draft:
      return badge("primary", cellData.text);
    case enumState.Canceled:
      return badge("danger", cellData.text);
    case enumState.Refused:
      return badge("danger", cellData.text);
    case enumState.Sended:
      return badge("warning", cellData.text);
    case enumState.final:
      return badge("info", cellData.text);
    case enumState.Accepted:
      return badge("success", cellData.text);
    default:
      break;
  }
}

export function stateInvoiceRender(state) {
  if (!state.value)
    switch (state.toString()) {
      case enumState.Draft:
        return badge("primary", "Brouillon");
      case enumState.final:
        return badge("info", "Finalisée");
      case enumState.Sended:
        return badge("warning", "Enoyée");
      case enumState.Paid:
        return badge("success", "Payée");
      default:
        break;
    }
  else
    switch (state.value.toString()) {
      case enumState.Draft:
        return badge("primary", "Brouillon");
      case enumState.final:
        return badge("info", "Finalisée");
      case enumState.Sended:
        return badge("warning", "Enoyée");
      case enumState.Paid:
        return badge("success", "Payée");
      default:
        break;
    }
}
