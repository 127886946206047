import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.css";
import frMessages from "devextreme/localization/messages/fr.json";
import { locale, loadMessages } from "devextreme/localization";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import { Layout } from "./components/Layout";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import "react-toastify/dist/ReactToastify.css";
import "./custom.css";
import UnitForm from "./components/UnitForm";
import ArticleForm from "./components/ArticleForm";
import Articles from "./components/Articles";
import CustomerForm from "./components/CustomerForm";
import QuoteForm from "./components/QuoteForm";
import Quotes from "./components/Quotes";
import InvoiceReport from "./components/InvoiceReport";
import InvoiceForm from "./components/InvoiceForm";
import NotFound from "./components/NotFound";
import Invoices from "./components/Invoices";
import Units from "./components/Units";
import Customers from "./components/Customers";
import DetailSections from "./components/DetailSections";
import DetailSectionForm from "./components/DetailSectionForm";
import AcceptedPayments from "./components/AcceptedPayments";
import AcceptedPaymentForm from "./components/AcceptedPaymentForm";
import Activities from "./components/Activities";
import ActivityForm from "./components/ActivityForm";
import Dashbords from "./components/Dashbords";
import Enterprises from "./components/Enterprises";
import EnterpriseForm from "./components/EnterpriseForm";
import Roles from "./components/Roles";
import RoleForm from "./components/RoleForm";
import UserRoles from "./components/UserRoles";
import UserContext from "./components/context/userContext";
import authService from "./components/api-authorization/AuthorizeService";
import hasRole from "./components/utils/userPermissions";
import ReadOnlyQuotes from "./components/ReadOnlyQuotes";
import roles from "./components/utils/roles";
import ReadOnlyCustomers from "./components/ReadOnlyCustomers";
import ReadOnlyInvoices from "./components/ReadOnlyInvoices";
import ExpensesNatures from "./components/ExpensesNatures";
import ExpenseNatureForm from "./components/ExpenseNatureForm";
import Expenses from "./components/Expenses";
import ExpenseForm from "./components/ExpenseForm";
import ReadOnlyArticles from "./components/ReadOnlyArticles";
import ReadOnlyDetailSections from "./components/ReadOnlyDetailSections";
import authorization from "./components/common/Authorization";

export default class App extends Component {
  constructor(props) {
    super(props);
    loadMessages(frMessages);
    locale(navigator.language);
  }
  static displayName = App.name;
  state = { currentUser: {} };
  async componentDidMount() {
    const currentUser = await authService.getUser();
    this.setState({ currentUser });
  }

  render() {
    const { currentUser } = this.state;
    return (
      <UserContext.Provider value={currentUser}>
        <Layout>
          <ToastContainer />
          <Switch>
            <AuthorizeRoute
              path="/unit/:id"
              component={authorization(
                currentUser,
                roles.canManageUnits,
                UnitForm
              )}
            />
            <AuthorizeRoute
              path="/list-units"
              component={authorization(
                currentUser,
                roles.canManageUnits,
                Units
              )}
            />
            <AuthorizeRoute
              path="/article/:id"
              component={authorization(
                currentUser,
                roles.canManageArticles,
                ArticleForm
              )}
            />
            <AuthorizeRoute
              path="/list-articles"
              component={
                hasRole(currentUser, roles.canManageArticles)
                  ? Articles
                  : ReadOnlyArticles
              }
            />
            <AuthorizeRoute
              path="/customer/:id"
              component={authorization(
                currentUser,
                roles.canManageCustomers,
                CustomerForm
              )}
            />
            <AuthorizeRoute
              path="/list-customers"
              component={
                hasRole(currentUser, roles.canManageCustomers)
                  ? Customers
                  : ReadOnlyCustomers
              }
            />
            <AuthorizeRoute
              path="/quote/:id"
              component={authorization(
                currentUser,
                roles.canManageQuotes,
                QuoteForm
              )}
            />
            <AuthorizeRoute
              path="/list-quotes"
              component={
                hasRole(currentUser, roles.canManageQuotes)
                  ? Quotes
                  : ReadOnlyQuotes
              }
            />
            <AuthorizeRoute
              path="/invoice/:id"
              component={authorization(
                currentUser,
                roles.canManageInvoice,
                InvoiceForm
              )}
            />
            <AuthorizeRoute
              path="/list-invoices"
              component={
                hasRole(currentUser, roles.canManageInvoice)
                  ? Invoices
                  : ReadOnlyInvoices
              }
            />
            <AuthorizeRoute path="/invoice-report" component={InvoiceReport} />
            <AuthorizeRoute
              path="/enterprise/:id"
              component={authorization(
                currentUser,
                roles.canManageEnterprises,
                EnterpriseForm
              )}
            />
            <AuthorizeRoute
              path="/list-enterprises"
              component={authorization(
                currentUser,
                roles.canManageEnterprises,
                Enterprises
              )}
            />
            <AuthorizeRoute
              path="/acceptedPayment/:id"
              component={authorization(
                currentUser,
                roles.canManageAcceptedPayments,
                AcceptedPaymentForm
              )}
            />
            <AuthorizeRoute
              path="/list-acceptedPayments"
              component={authorization(
                currentUser,
                roles.canManageAcceptedPayments,
                AcceptedPayments
              )}
            />
            <AuthorizeRoute
              path="/detailSection/:id"
              component={authorization(
                currentUser,
                roles.canManageDetailSections,
                DetailSectionForm
              )}
            />
            <AuthorizeRoute
              path="/list-detailSections"
              component={
                hasRole(currentUser, roles.canManageDetailSections)
                  ? DetailSections
                  : ReadOnlyDetailSections
              }
            />
            <AuthorizeRoute
              path="/activity/:id"
              component={authorization(
                currentUser,
                roles.canManageActivities,
                ActivityForm
              )}
            />
            <AuthorizeRoute
              path="/list-activities"
              component={authorization(
                currentUser,
                roles.canManageActivities,
                Activities
              )}
            />
            <AuthorizeRoute
              path="/role/:id"
              component={authorization(
                currentUser,
                roles.canManageUsers,
                RoleForm
              )}
            />
            <AuthorizeRoute
              path="/list-roles"
              component={authorization(
                currentUser,
                roles.canManageUsers,
                Roles
              )}
            />
            <AuthorizeRoute
              path="/user-roles"
              component={authorization(
                currentUser,
                roles.canManageUsers,
                UserRoles
              )}
            />
            <AuthorizeRoute
              path="/expensesNature"
              component={authorization(
                currentUser,
                roles.canManageExpensesNatures,
                ExpenseNatureForm
              )}
            />
            <AuthorizeRoute
              path="/natures-expenses"
              component={authorization(
                currentUser,
                roles.canManageExpensesNatures,
                ExpensesNatures
              )}
            />
            <AuthorizeRoute
              path="/expense/:id"
              component={authorization(
                currentUser,
                roles.canManageExpensesNatures,
                ExpenseForm
              )}
            />
            <AuthorizeRoute
              path="/list-expenses"
              component={authorization(
                currentUser,
                roles.canManageExpensesNatures,
                Expenses
              )}
            />
            <AuthorizeRoute path="/dashbord" component={Dashbords} />
            <AuthorizeRoute path="/not-found" component={NotFound} />
            <Route
              path={ApplicationPaths.ApiAuthorizationPrefix}
              component={ApiAuthorizationRoutes}
            />
            <Redirect from="/" to="/list-quotes" exact />
            <Redirect to="/not-found" />
          </Switch>
        </Layout>
      </UserContext.Provider>
    );
  }
}
