import React from "react";
import formatCfa from "./utils/formatCfa";

const DownPaymentTotal = ({ downPayments }) => {
  return downPayments.map((i, index) => (
    <div className="row subtotal" key={index}>
      <div className="col-6 left">Acompte {i.number}</div>
      <div className="col-6 rigth">{formatCfa(i.totalHT)}</div>
    </div>
  ));
};

export default DownPaymentTotal;
