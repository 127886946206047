import http from "./httpService";

const endPoint = "activities";

function getActivities() {
  return http.get(endPoint);
}

function activityUrl(id) {
  return `${endPoint}/${id}`;
}

function saveActivity(activity) {
  if (activity.id === undefined || activity.id === 0)
    return http.post(endPoint, activity);
  return http.put(activityUrl(activity.id), activity);
}

function getActivity(activityId) {
  return http.get(activityUrl(`GetActivity/${activityId}`));
}

export default {
  getActivities,
  getActivity,
  saveActivity,
};
