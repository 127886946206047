export default function (errors) {
  errors.forEach((err) => {
    switch (err.type) {
      case "any.empty":
        err.message = "Ce champ est requis!";
        break;
      case "string.min":
        err.message = `Value should have at least ${err.local.limit} characters!`;
        break;
      case "string.max":
        err.message = `Value should have at most ${err.local.limit} characters!`;
        break;
      case "number.base":
        err.message = `"${err.flags.label}" doit être un nombre`;
        break;
      case "number.min":
        err.message = `"${err.flags.label}" doit être supérieure ou égale à 0`;
        break;
      case "string.email":
        err.message = `${err.flags.label} doit être une adresse électronique valide!`;
        break;
      default:
        break;
    }
  });
  return errors;
}
