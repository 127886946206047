import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import roleService from "../services/roleService";
import errors from "./utils/errors";
import { toast } from "react-toastify";

class RoleForm extends Form {
  state = {
    data: {
      name: "",
      description: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.any().optional(),
    name: Joi.string().required().label("Libelle").error(errors),
    description: Joi.string().required().label("Description").error(errors),
  };

  async populateRole() {
    const roleId = this.props.match.params.id;
    try {
      if (roleId === "new") {
        const role = {
          name: "",
          description: "",
        };
        this.setState({ data: role });
        return;
      }
      const { data } = await roleService.getRole(roleId);
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateRole();
  }

  doSubmit = async () => {
    try {
      await roleService.saveRole(this.state.data);
      this.props.history.push("/list-roles");
      toast.success("Le rôle a été enregistré.");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="w-50">
          {this.renderInput("name", "Libelle")}
          {this.renderInput("description", "Description")}
          {this.renderButton("Enregistrer")}
        </form>
      </div>
    );
  }
}

export default RoleForm;
