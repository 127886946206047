import React, { useEffect, useState } from "react";

import customerService from "../services/customerService";
import Grid from "./common/Grid";

export default function ReadOnlyCustomers(props) {
  const [customers, setCustomers] = useState([]);
  const columns = [
    { dataField: "number", caption: "Numéro" },
    { dataField: "name", caption: "Libelle" },
    {
      dataField: "type",
      caption: "Type",
      dataSource: [
        { key: 1, value: "Particulier" },
        { key: 2, value: "Entreprise" },
      ],
      displayExpr: "value",
      valueExpr: "key",
    },
    { dataField: "adresses", caption: "Adresse" },
    { dataField: "phone", caption: "Téléphone" },
    { dataField: "email", caption: "Mail" },
  ];
  const editing = {
    mode: "row",
    allowAdding: false,
    allowDeleting: false,
    allowUpdating: false,
  };

  useEffect(() => {
    async function getCustomers() {
      const { data: customers } = await customerService.getCustomers();
      setCustomers(customers);
    }

    getCustomers();
  }, [props]);

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={customers}
        editing={editing}
        isGoupPanelVisible={true}
      />
    </div>
  );
}
