import {
  getDetailArticles,
  getSousSectionLastIndex,
  addZeroPointOneToNumber,
} from "./number";

export function insertUp(isSection, index, detail, data) {
  if (detail.numberSection === 0) {
    insertUpDetailWithoutParent(isSection, index, detail, data);
    return;
  }
  insertUpInsideSection(isSection, index, detail, data);
}

export function insertDown(isSection, index, detail, data) {
  if (detail.numberSection === 0) {
    const articles = getDetailArticles(data, detail);
    if (articles.length === 0) {
      insertDownDetailWithoutParent(isSection, index, detail, data);
      return;
    }
    insertDownWithChilds(isSection, index, detail, data);
    return;
  }
  insertDownInsideSection(isSection, index, detail, data);
}

export function insertUpDetailWithoutParent(isSection, index, detail, data) {
  const item = {
    isSection,
    number: detail.number,
    numberSection: 0,
    detailSectionId: "",
    articleId: "",
    unitId: "",
    quantity: "1",
    price: "",
    total: "",
  };
  for (let i = index; i < data.details.length; i++) {
    data.details[i] = { ...data.details[i] };
    if (data.details[i].numberSection !== 0) {
      data.details[i].number = parseFloat(
        (data.details[i].number + 1).toFixed(1)
      );
      data.details[i].numberSection = data.details[i].numberSection + 1;
      continue;
    }
    data.details[i].number = data.details[i].number + 1;
  }
  data.details.splice(index, 0, item);
}

export function insertUpInsideSection(isSection, index, detail, data) {
  const item = {
    isSection,
    number: detail.number,
    numberSection: detail.numberSection,
    detailSectionId: "",
    articleId: "",
    unitId: "",
    quantity: "1",
    price: "",
    total: "",
  };

  const indexLastSousSection = getSousSectionLastIndex(data, detail);

  for (let i = index; i <= indexLastSousSection; i++) {
    data.details[i] = { ...data.details[i] };
    data.details[i].number = addZeroPointOneToNumber(data.details[i].number);
  }
  data.details.splice(index, 0, item);
}

export function insertDownDetailWithoutParent(isSection, index, detail, data) {
  const item = {
    isSection,
    number: detail.number + 1,
    numberSection: 0,
    detailSectionId: "",
    articleId: "",
    unitId: "",
    quantity: "1",
    price: "",
    total: "",
  };
  for (let i = index + 1; i < data.details.length; i++) {
    data.details[i] = { ...data.details[i] };
    if (data.details[i].numberSection !== 0) {
      data.details[i].number = parseFloat(
        (data.details[i].number + 1).toFixed(1)
      );
      data.details[i].numberSection = data.details[i].numberSection + 1;
      continue;
    }
    data.details[i].number = data.details[i].number + 1;
  }
  data.details.splice(index + 1, 0, item);
}

export function insertDownInsideSection(isSection, index, detail, data) {
  const item = {
    isSection,
    number: addZeroPointOneToNumber(detail.number),
    numberSection: detail.numberSection,
    detailSectionId: "",
    articleId: "",
    unitId: "",
    quantity: "1",
    price: "",
    total: "",
  };
  const indexLastSousSection = getSousSectionLastIndex(data, detail);

  for (let i = index + 1; i <= indexLastSousSection; i++) {
    data.details[i] = { ...data.details[i] };
    data.details[i].number = addZeroPointOneToNumber(data.details[i].number);
  }
  data.details.splice(index + 1, 0, item);
}

export function insertDownWithChilds(isSection, index, detail, data) {
  const item = {
    isSection,
    number: detail.number + 1,
    numberSection: 0,
    detailSectionId: "",
    articleId: "",
    unitId: "",
    quantity: "1",
    price: "",
    total: "",
  };
  const articles = getDetailArticles(data, detail);
  const indexLastSousSection = data.details.indexOf(
    articles[articles.length - 1]
  );

  for (let i = indexLastSousSection + 1; i < data.details.length; i++) {
    data.details[i] = { ...data.details[i] };
    if (data.details[i].numberSection !== 0) {
      data.details[i].number = parseFloat(
        (data.details[i].number + 1).toFixed(1)
      );
      data.details[i].numberSection = data.details[i].numberSection + 1;
      continue;
    }
    data.details[i].number = data.details[i].number + 1;
  }
  data.details.splice(indexLastSousSection + 1, 0, item);
}
