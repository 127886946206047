import React from "react";

const Discount = (props) => {
  const { onHandleChange, data } = props;
  return (
    <div className="row">
      <div className="col-3">Reduction</div>
      <div className="col-6 form-group">
        <input
          type="number"
          min={0}
          className="form-control"
          name="rateDiscount"
          value={data["rateDiscount"]}
          onChange={(e) => onHandleChange(e)}
        />
      </div>
      <div className="col-3 form-group">
        <select
          name="modeDiscount"
          id="modeDiscount"
          className="form-control"
          onChange={(e) => onHandleChange(e)}
          value={data["modeDiscount"]}
        >
          <option>FCFA</option>
          <option>%</option>
        </select>
      </div>
    </div>
  );
};

export default Discount;
