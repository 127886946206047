import http from "./httpService";

const endPoint = "articles";

function articleUrl(id) {
  return `${endPoint}/${id}`;
}

function saveArticle(article) {
  if (article.id === undefined || article.id === 0)
    return http.post(endPoint, article);
  return http.put(articleUrl(article.id), article);
}

function getArticles() {
  return http.get(endPoint);
}

function getArticle(articleId) {
  return http.get(articleUrl(`GetArticle/${articleId}`));
}

function deleteArticle(articleId) {
  return http.delete(articleUrl(articleId));
}

export default {
  saveArticle,
  getArticles,
  getArticle,
  deleteArticle,
};
