import React, { useEffect, useState } from "react";
import enterpriseService from "../services/enterpriseService";
import Grid from "./common/Grid";

function Enterprises(props) {
  const [enterprises, setEnterprises] = useState([]);
  const columns = [
    { dataField: "name", caption: "Libelle" },
    { dataField: "webSite", caption: "Site web" },
    { dataField: "mail", caption: "mail" },
    { dataField: "phone", caption: "Tél" },
    { dataField: "adress", caption: "Adresse" },
    { dataField: "nif", caption: "Nif" },
    { dataField: "rateTVA", caption: "Taux TVA" },
  ];
  const editing = {
    mode: "row",
    allowAdding: false,
    allowDeleting: false,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier la catégorie",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/enterprise/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    (async function () {
      const { data } = await enterpriseService.getEnterprises();
      setEnterprises(data);
    })();
  }, [props]);

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={enterprises}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
      />
    </div>
  );
}

export default Enterprises;
