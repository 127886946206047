import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import unitService from "../services/unitService";
import errors from "./utils/errors";
import { toast } from "react-toastify";

class UnitForm extends Form {
  state = {
    data: {
      name: "",
      code: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.any().optional(),
    name: Joi.string().required().label("Libelle").error(errors),
    code: Joi.string().required().label("Code").error(errors),
  };

  async populateUnit() {
    const unitId = this.props.match.params.id;
    try {
      if (unitId === "new") {
        const unit = {
          name: "",
          code: "",
        };
        this.setState({ data: unit });
        return;
      }
      const { data } = await unitService.getUnit(parseInt(unitId));
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateUnit();
  }

  doSubmit = async () => {
    try {
      await unitService.saveUnit(this.state.data);
      this.props.history.push("/list-units");
      toast.success("L'unité a été enregistrée.");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        if (ex.response.data.errors.Name) {
          errors.unitId = "Ce champ est requis";
        }
        if (ex.response.data.errors.Code) {
          errors.familyId = "Ce champ est requis";
        }
        this.setState({ errors });
      }
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="w-50">
          {this.renderInput("name", "Libelle")}
          {this.renderInput("code", "Code")}
          {this.renderButton("Enregistrer")}
        </form>
      </div>
    );
  }
}

export default UnitForm;
