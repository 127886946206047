const roles = {
  canManageQuotes: "CanManageQuotes",
  canCreateInvoiceByQuote: "CanCreateInvoiceByQuote",
  canManageDetailSections: "CanManageDetailSections",
  canManageUsers: "CanManageUsers",
  canManageActivities: "CanManageActivities",
  canManageCustomers: "CanManageCustomers",
  canManageUnits: "CanManageUnits",
  canManageModePayments: "CanManageModePayments",
  canManageEnterprises: "CanManageEnterprises",
  canManageArticles: "CanManageArticles",
  canManageInvoice: "CanManageInvoice",
  canManageAcceptedPayments: "CanManageAcceptedPayments",
  canCreateEntity: "CanCreateEntity",
  canManageExpensesNatures: "CanManageExpensesNatures",
};

export default roles;
