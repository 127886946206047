// export const actions = [
//   { id: 1, text: "Brouillon", icon: "rename" },
//   { id: 2, text: "Accepté", icon: "check" },
//   { id: 3, text: "Refusé", icon: "close" },
//   { id: 4, text: "Finalisé", icon: "download" },
//   { id: 5, text: "Modifier", icon: "edit" },
//   { id: 6, text: "Exporter", icon: "pdffile" },
//   { id: 7, text: "Supprimer", icon: "trash" },
// ];
export const actions = [
  { id: 1, text: "Brouillon", icon: "rename" },
  { id: 2, text: "Finalisé", icon: "download" },
  { id: 3, text: "Accepté", icon: "check" },
  { id: 4, text: "Refusé", icon: "close" },
  { id: 5, text: "Modifier", icon: "edit" },
  { id: 6, text: "Exporter", icon: "pdffile" },
  { id: 7, text: "Supprimer", icon: "trash" },
];

// export const actionsWithFacture = [
//   { id: 1, text: "Brouillon", icon: "rename" },
//   { id: 2, text: "Accepté", icon: "check" },
//   { id: 3, text: "Refusé", icon: "close" },
//   { id: 4, text: "Finalisé", icon: "download" },
//   { id: 5, text: "Modifier", icon: "edit" },
//   { id: 6, text: "Exporter", icon: "pdffile" },
//   { id: 7, text: "Supprimer", icon: "trash" },
//   { id: 8, text: "Facture d'acompte", icon: "doc" },
//   { id: 9, text: "Facture finale", icon: "doc" },
// ];
export const actionsWithFacture = [
  { id: 1, text: "Brouillon", icon: "rename" },
  { id: 2, text: "Finalisé", icon: "download" },
  { id: 3, text: "Accepté", icon: "check" },
  { id: 4, text: "Refusé", icon: "close" },
  { id: 5, text: "Modifier", icon: "edit" },
  { id: 6, text: "Exporter", icon: "pdffile" },
  { id: 7, text: "Supprimer", icon: "trash" },
  { id: 8, text: "Facture d'acompte", icon: "doc" },
  { id: 9, text: "Facture finale", icon: "doc" },
];

// export const actionsWithFactureDisabled = [
//   { id: 1, text: "Brouillon", icon: "rename" },
//   { id: 2, text: "Accepté", icon: "check" },
//   { id: 3, text: "Refusé", icon: "close" },
//   { id: 4, text: "Finalisé", icon: "download" },
//   { id: 5, text: "Modifier", icon: "edit" },
//   { id: 6, text: "Exporter", icon: "pdffile" },
//   { id: 7, text: "Supprimer", icon: "trash" },
//   { id: 8, text: "Facture d'acompte", icon: "doc", disabled: true },
//   { id: 9, text: "Facture finale", icon: "doc", disabled: true },
// ];
export const actionsWithFactureDisabled = [
  { id: 1, text: "Brouillon", icon: "rename" },
  { id: 2, text: "Finalisé", icon: "download" },
  { id: 3, text: "Accepté", icon: "check" },
  { id: 4, text: "Refusé", icon: "close" },
  { id: 5, text: "Modifier", icon: "edit" },
  { id: 6, text: "Exporter", icon: "pdffile" },
  { id: 7, text: "Supprimer", icon: "trash" },
  { id: 8, text: "Facture d'acompte", icon: "doc", disabled: true },
  { id: 9, text: "Facture finale", icon: "doc", disabled: true },
];

// export const actionsState = [
//   { id: 1, text: "Brouillon", icon: "rename" },
//   { id: 2, text: "Accepté", icon: "check" },
//   { id: 3, text: "Refusé", icon: "close" },
//   { id: 4, text: "Finalisé", icon: "download" },
// ];
export const actionsState = [
  { id: 1, text: "Brouillon", icon: "rename" },
  { id: 2, text: "Finalisé", icon: "download" },
  { id: 3, text: "Accepté", icon: "check" },
  { id: 4, text: "Refusé", icon: "close" },
];

export const actionsInvoice = [
  { id: 1, text: "Brouillon", icon: "rename" },
  { id: 2, text: "Finalisée", icon: "download" },
  { id: 3, text: "Envoyée", icon: "download" },
  { id: 4, text: "Payée", icon: "check" },
  { id: 5, text: "Modifier", icon: "edit" },
  { id: 6, text: "Exporter", icon: "pdffile" },
  { id: 7, text: "Supprimer", icon: "trash" },
];

export const actionsInvoiceState = [
  { id: 1, text: "Brouillon", icon: "rename" },
  { id: 2, text: "Finalisée", icon: "download" },
  { id: 3, text: "Envoyée", icon: "download" },
  { id: 4, text: "Payée", icon: "check" },
];

// readonlyQuote Actions
export const readOnlyActionsWithFacture = [
  { id: 1, text: "Exporter", icon: "pdffile" },
  { id: 2, text: "Facture d'acompte", icon: "doc" },
  { id: 3, text: "Facture finale", icon: "doc" },
];

export const readOnlyActionsWithFactureDisabled = [
  { id: 1, text: "Exporter", icon: "pdffile" },
  { id: 2, text: "Facture d'acompte", icon: "doc", disabled: true },
  { id: 3, text: "Facture finale", icon: "doc", disabled: true },
];

export const readOnlyActions = [{ id: 6, text: "Exporter", icon: "pdffile" }];

//readOnlyInvoice Acions
export const readOnlyActionsInvoice = [
  { id: 1, text: "Exporter", icon: "pdffile" },
];
