import React from "react";
import Select from "./common/Select";

const Condtitions = (props) => {
  const {
    activities,
    condtions,
    onHandleChangeCondition: onHandleChange,
    onRemoveCondition,
  } = props;
  return (
    <div className="row mt-3">
      {condtions.map((condition, i) => (
        <React.Fragment key={i}>
          <div className="col-2">Acompte de </div>
          <div className="col-3">
            <input
              type="number"
              min={0}
              className="form-control"
              name="montant"
              value={condition["montant"]}
              onChange={(e) => onHandleChange(e, condition)}
            />
          </div>
          <div className="col-2">
            <select
              name="modePayment"
              id="modePayment"
              className="form-control"
              value={condition["modePayment"]}
              onChange={(e) => onHandleChange(e, condition)}
            >
              <option>%</option>
              <option>FCFA</option>
            </select>
          </div>
          <div className="col-3">
            <Select
              name={"activityId"}
              id={"activityId"}
              value={condition["activityId"]}
              onChange={(e) => onHandleChange(e, condition)}
              data={activities}
            />
          </div>
          <div className="col-2">
            <button
              className="btn btn-danger"
              onClick={() => onRemoveCondition(condition)}
            >
              Supprimer
            </button>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Condtitions;
