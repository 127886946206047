export const menuItems = [
  {
    icon: "bulletlist",
    items: [
      {
        name: "Insérer au dessus",
        icon: "arrowup",
        items: [
          { id: "1.1", name: "Article" },
          { id: "1.2", name: "Section" },
        ],
      },
      {
        name: "Insérer au dessous",
        icon: "arrowdown",
        items: [
          { id: "2.1", name: "Article" },
          { id: "2.2", name: "Section" },
        ],
      },
      { id: "3", name: "Supprimer cette ligne", icon: "trash" },
    ],
  },
];

export const menuItemsWithoutSection = [
  {
    icon: "bulletlist",
    items: [
      {
        name: "Insérer au dessus",
        icon: "arrowup",
        items: [{ id: "1.1", name: "Article" }],
      },
      {
        name: "Insérer au dessous",
        icon: "arrowdown",
        items: [{ id: "2.1", name: "Article" }],
      },
      { id: "3", name: "Supprimer cette ligne", icon: "trash" },
    ],
  },
];
