import http from "./httpService";

const endPoint = "expensesNatures";

function getExpensesNatures() {
  return http.get(endPoint);
}

function expensesNatureUrl(id) {
  return `${endPoint}/${id}`;
}

function saveExpensesNature(expensesNature) {
  if (expensesNature.id === undefined || expensesNature.id === 0)
    return http.post(endPoint, expensesNature);
  return http.put(expensesNatureUrl(expensesNature.id), expensesNature);
}

function getExpensesNature(expensesNatureId) {
  return http.get(expensesNatureUrl(`GetExpensesNature/${expensesNatureId}`));
}

export default {
  getExpensesNatures,
  getExpensesNature,
  saveExpensesNature,
};
