import React, { useEffect, useState } from "react";
import detailSectionService from "../services/detailSectionService";
import Grid from "./common/Grid";

function DetailSections(props) {
  const [families, setDetailSections] = useState([]);
  const columns = [
    { dataField: "number", caption: "N°" },
    { dataField: "name", caption: "Libelle" },
  ];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: false,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier la catégorie",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/detailsection/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    (async function () {
      const { data } = await detailSectionService.getDetailSections();
      setDetailSections(data);
    })();
  }, [props]);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint = "Ajouter une nouvelle catégorie";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/detailsection/new");
    e.toolbarOptions.items.unshift();
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={families}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
      />
    </div>
  );
}

export default DetailSections;
