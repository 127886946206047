import React from "react";

const SimpleDataList = ({ list, name, label, data, value, error, ...rest }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type="text"
        id={name}
        list={list}
        name={name}
        value={value}
        className="form-control"
        autoComplete="off"
        {...rest}
      />
      <datalist id={list}>
        {data.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      </datalist>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default SimpleDataList;
