export const enumState = {
  Draft: "1",
  Accepted: "2",
  Refused: "3",
  Canceled: "4",
  Sended: "5",
  final: "6",
  Paid: "7",
};

export const state = [
  { key: 1, value: "Brouillon" },
  { key: 2, value: "Accepté" },
  { key: 3, value: "Refusé" },
  { key: 4, value: "Annulé" },
  { key: 5, value: "Envoyé" },
  { key: 6, value: "Finalisé" },
];

export const liststateInvoice = [
  { key: 1, value: "Brouillon" },
  { key: 5, value: "Envoyée" },
  { key: 6, value: "Finalisée" },
  { key: 7, value: "Payée" },
];

export const enumTypeInvoice = {
  Final: 1,
  DownPayment: 2,
};
