import React from "react";
const DataListString = ({ list, name, label, data, value, error, ...rest }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type="text"
        id={name}
        list={list}
        name={name}
        value={
          Array.isArray(data)
            ? data.find((d) => d.id === value) !== undefined
              ? data.find((d) => d.id === value).name
              : value
            : ""
        }
        className="form-control"
        autoComplete="off"
        {...rest}
      />
      <datalist id={list}>
        {Array.isArray(data)
          ? data.map((option, index) => (
              <option key={index} data-value={option.id}>
                {option.name}
              </option>
            ))
          : null}
      </datalist>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default DataListString;
