import http from "./httpService";

const endPoint = "customers";

function customerUrl(id) {
  return `${endPoint}/${id}`;
}

function saveCustomer(customer) {
  if (customer.id === undefined || customer.id === 0)
    return http.post(endPoint, customer);
  return http.put(customerUrl(customer.id), customer);
}

function getCustomers() {
  return http.get(customerUrl("GetCustomers"));
}

function getCustomer(customerId) {
  return http.get(customerUrl(`GetCustomer/${customerId}`));
}

function deleteCustomer(customerId) {
  return http.delete(customerUrl(customerId));
}

function getCustomersFirstName() {
  return http.get(customerUrl("GetCustomersFirstName"));
}
function getCustomersLastName() {
  return http.get(customerUrl("GetCustomersLastName"));
}
function getCustomersEnterpriseName() {
  return http.get(customerUrl("GetCustomersEnterpriseName"));
}
function getCustomersAdresses() {
  return http.get(customerUrl("GetCustomersAdresses"));
}
function getCustomersPhone() {
  return http.get(customerUrl("GetCustomersPhone"));
}
function getCustomersOtherPhone() {
  return http.get(customerUrl("GetCustomersOtherPhone"));
}
function getCustomersEmail() {
  return http.get(customerUrl("GetCustomersEmail"));
}
function getCustomersOtherEmail() {
  return http.get(customerUrl("GetCustomersOtherEmail"));
}

export default {
  getCustomers,
  getCustomer,
  saveCustomer,
  deleteCustomer,
  getCustomersFirstName,
  getCustomersLastName,
  getCustomersEnterpriseName,
  getCustomersAdresses,
  getCustomersPhone,
  getCustomersOtherPhone,
  getCustomersEmail,
  getCustomersOtherEmail,
};
