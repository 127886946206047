import React, { useEffect, useState } from "react";
import invoiceService from "../services/invoiceService";
import Grid from "./common/Grid";
import { enumState, liststateInvoice } from "./utils/state";
import { stateInvoiceRender } from "./utils/stateCell";
import { actionsInvoice } from "./utils/actions";
import { printInvoiceInNewWindow } from "./utils/printInNewWindow";
import { toast } from "react-toastify";
import Modal from "./common/Modal";

export default function Invoices(props) {
  const [invoices, setInvoices] = useState([]);
  const [modalOptions, SetModalOptions] = useState({
    isPopupVisible: false,
    width: 500,
    height: 222,
    message: "Confirmer la suppression?",
    toolbarItems: [],
  });

  const columns = [
    { dataField: "number", caption: "Numéro" },
    {
      dataField: "type",
      caption: "Type",
      dataSource: [
        { key: 1, value: "Finale" },
        { key: 2, value: "Acompte" },
      ],
      displayExpr: "value",
      valueExpr: "key",
    },
    {
      dataField: "state",
      caption: "Statut",
      dataSource: liststateInvoice,
      displayExpr: "value",
      valueExpr: "key",
      cellRender: stateInvoiceRender,
    },
    { dataField: "customer.name", caption: "Client" },
    { dataField: "quote.name", caption: "N° devis" },
    { dataField: "projectName", caption: "Projet" },
    { dataField: "invoiceDate", caption: "En date du", dataType: "date" },
    { dataField: "totalHT", caption: "Total HT", format: "#,##0 CFA" },
    { dataField: "reste", caption: "Restant dû", format: "#,##0 CFA" },
    { dataField: "creator.name", caption: "Créer par" },
    { dataField: "creationDate", caption: "Date création", dataType: "date" },
    { dataField: "modifier.name", caption: "Modifier par" },
    { dataField: "lastUpdate", caption: "Date modification", dataType: "date" },
    { dataField: "reportNumber", caption: "N° rapport" },
  ];
  const editing = {
    mode: "row",
    allowAdding: true,
  };

  useEffect(() => {
    async function getInvoices() {
      const { data } = await invoiceService.getInvoices();
      setInvoices(data);
    }

    getInvoices();
  }, [props]);

  const renderModal = (message, toolbarItems) => {
    SetModalOptions((prevState) => ({
      ...prevState,
      isPopupVisible: true,
      message,
      toolbarItems,
    }));
  };

  const handleChangeInvoiceState = async (invoiceId, state) => {
    const { data: invoice } = await invoiceService.changeInvoiceState(
      invoiceId,
      state
    );
    const allInvoices = [...invoices];
    const invoiceToChange = allInvoices.find((q) => q.id === invoice.id);
    const index = allInvoices.indexOf(invoiceToChange);
    allInvoices[index] = invoice;
    setInvoices(allInvoices);
    toast.success(`Le statut de la facture N°${invoice.number} a changé`);
  };

  const onActionClick = async ({ itemData: action }, { data: row }) => {
    switch (action.id) {
      case actionsInvoice[4].id:
        props.history.push(`/invoice/${row.id}`);
        break;
      case actionsInvoice[5].id:
        printInvoiceInNewWindow(row.id);
        break;
      case actionsInvoice[0].id:
        handleChangeInvoiceState(row.id, parseInt(enumState.Draft));
        break;
      case actionsInvoice[1].id:
        handleChangeInvoiceState(row.id, parseInt(enumState.final));
        break;
      case actionsInvoice[2].id:
        handleChangeInvoiceState(row.id, parseInt(enumState.Sended));
        break;
      case actionsInvoice[3].id:
        handleChangeInvoiceState(row.id, parseInt(enumState.Paid));
        break;
      case actionsInvoice[6].id:
        renderModal("Confirmer la suppression?", [
          {
            options: {
              text: "Oui, supprimer",
              stylingMode: "contained",
              type: "danger",
              onClick: () => {
                handleDeleteInvoice(row.id);
                SetModalOptions((prevState) => ({
                  ...prevState,
                  isPopupVisible: false,
                }));
              },
            },
          },
        ]);
        break;
      default:
        break;
    }
  };

  const handleDeleteInvoice = async (invoiceId) => {
    let allInvoices = [...invoices];
    allInvoices = allInvoices.filter((a) => a.id !== invoiceId);
    setInvoices(allInvoices);
    await invoiceService.deleteInvoice(invoiceId);
    toast.success("La facture a été supprimée");
  };

  const togglePopup = () => {
    SetModalOptions((prevState) => ({
      ...prevState,
      isPopupVisible: !modalOptions.isPopupVisible,
    }));
  };

  return (
    <div>
      <h4>Liste des factures</h4>

      <Modal
        isPopupVisible={modalOptions.isPopupVisible}
        togglePopup={togglePopup}
        width={modalOptions.width}
        height={modalOptions.height}
        message={modalOptions.message}
        toolbarItems={modalOptions.toolbarItems}
      />
      <Grid
        columns={columns}
        dataSource={invoices}
        editing={editing}
        isGoupPanelVisible={true}
        dropDownButton={true}
        actions={[actionsInvoice, actionsInvoice, actionsInvoice]}
        onActionClick={onActionClick}
      />
    </div>
  );
}
