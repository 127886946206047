import React, { useEffect, useState } from "react";
import unitService from "../services/unitService";
import Grid from "./common/Grid";

function Units(props) {
  const [units, setUnits] = useState([]);
  const columns = [
    { dataField: "name", caption: "Libelle" },
    { dataField: "code", caption: "Code" },
  ];
  const editing = {
    mode: "row",
    allowAdding: true,
    allowDeleting: false,
    allowUpdating: true,
  };

  const buttons = [
    {
      hint: "Modifier l'unité",
      icon: "edit",
      onClick: function (e) {
        props.history.push(`/unit/${e.row.data.id}`);
      },
    },
    "delete",
  ];

  useEffect(() => {
    (async function () {
      const { data } = await unitService.getUnitsWithCode();
      setUnits(data);
    })();
  }, []);

  const handleOnToolbarPreparing = (e) => {
    e.toolbarOptions.items[1].options.hint = "Ajouter une nouvelle unité";
    e.toolbarOptions.items[1].options.onClick = () =>
      props.history.replace("/unit/new");
    e.toolbarOptions.items.unshift();
  };

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={units}
        editing={editing}
        isGoupPanelVisible={true}
        buttons={buttons}
        onToolbarPreparing={handleOnToolbarPreparing}
      />
    </div>
  );
}

export default Units;
