import React from "react";
import formatCfa from "./utils/formatCfa";
import { stateInvoiceRender } from "./utils/stateCell";

const DownPayment = ({ downPayments, quoteNumber, onDeduct }) => {
  return (
    <div className="mt-5">
      <p>Historique de facturation pour le devis {quoteNumber}</p>
      {downPayments.map((i, index) => (
        <div key={index}>
          <div className="row">
            <div className="col-2">{stateInvoiceRender(i.state)}</div>
            <div className="col-4 ">
              <strong>
                Acompte #{index + 1} n° {i.number}
              </strong>
            </div>
            <div className="col-3 btn-deduire">{formatCfa(i.totalHT)}</div>
            <div className="col-3 btn-deduire">
              <button
                className={i.isDeduct ? "btn btn-success" : "btn btn-light"}
                onClick={() => onDeduct(index)}
              >
                Déduire
              </button>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default DownPayment;
