import React, { useEffect, useState } from "react";

import articleService from "../services/articleService";
import Grid from "./common/Grid";

export default function ReadOnlyArticles(props) {
  const [articles, setArticles] = useState([]);
  const columns = [
    { dataField: "number", caption: "N°" },
    { dataField: "detailSection.name", caption: "Catégorie" },
    { dataField: "name", caption: "Article" },
    {
      dataField: "price",
      caption: "Prix",
      dataType: "number",
      format: "#,##0 CFA",
    },
    { dataField: "unit.name", caption: "Unité" },
  ];
  const editing = {
    mode: "row",
    allowAdding: false,
    allowDeleting: false,
    allowUpdating: false,
  };

  useEffect(() => {
    async function getArticles() {
      const { data: articles } = await articleService.getArticles();
      setArticles(articles);
    }

    getArticles();
  }, [props]);

  return (
    <div>
      <Grid
        columns={columns}
        dataSource={articles}
        editing={editing}
        isGoupPanelVisible={true}
      />
    </div>
  );
}
