import React from "react";
import Form from "./common/Form";
import Joi from "joi-browser";
import activityService from "../services/activityService";
import errors from "./utils/errors";
import { toast } from "react-toastify";

class ActivityForm extends Form {
  state = {
    data: {
      name: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.any().optional(),
    name: Joi.string().required().label("Libelle").error(errors),
  };

  async populateActivity() {
    const activityId = this.props.match.params.id;
    try {
      if (activityId === "new") {
        const activity = {
          name: "",
        };
        this.setState({ data: activity });
        return;
      }
      const { data } = await activityService.getActivity(parseInt(activityId));
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateActivity();
  }

  doSubmit = async () => {
    try {
      await activityService.saveActivity(this.state.data);
      this.props.history.push("/list-activities");
      toast.success("La catégorie a été enregistrée.");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        if (ex.response.data.errors.Name) {
          errors.activityId = "Ce champ est requis";
        }
        this.setState({ errors });
      }
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="w-50">
          {this.renderInput("name", "Libelle")}
          {this.renderButton("Enregistrer")}
        </form>
      </div>
    );
  }
}

export default ActivityForm;
